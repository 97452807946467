<template>
  <div class="resources-view-container">
    <div class="resources-title-container">
      <span class="resources-title">{{$t("resources_v2.products_title")}}</span>
    </div>
    <div class="resources_container" v-if="this.productResources.length > 0">
      <ProductResourceCard class="resource-item"
          v-for="productResource in productResources"
          :key="productResource.id"
          :id="productResource.id"
          :productResource="productResource"/>
    </div>
    <div class="container-loader" v-if="this.productResources.length === 0">
      <InifiniteProgressLoader/>
    </div>
  </div>
</template>
<script>

import ProductResourceCard from "@/components/ResourcesComponent/ProductResourceCard";
import {getProductResources} from "@/resources";
import InifiniteProgressLoader from "@/components/GreenhouseComponent/InifiniteProgressLoader";

export default {
  components: {ProductResourceCard, InifiniteProgressLoader},
  data() {
    return {
      productResources: [],
    };
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
        () => this.$route.params,
        () => {
          this.fetchData()
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )
  },
  methods: {
    fetchData: async function () {
      try {
        this.productResources = await getProductResources();
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>
<style scoped>
.resources-view-container {
  max-width: 1300px;
  padding: 0 16px;
  margin: 0 auto;
  margin-top: 20px;
}

.container-loader {
  padding: 50px;
}

.resources_container {
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}


h1 {
  font-family: var(--secondary-font);
  margin-bottom: 0;
  font-size: 18px;
}

.resources-title-container {
  margin: 32px;
}

.resources-title {
  font-family: var(--secondary-font);
  font-weight: 600;
  font-size: 36px;
  letter-spacing: 3px;
}

@media (max-width: 600px) {
  .resources-title {
    font-family: var(--secondary-font);
    font-weight: 600;
    font-size: 32px;
    letter-spacing: 3px;
  }
}

.resource-item {
  width: 33%
}

</style>