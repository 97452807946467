import {db, storage} from "./firebase";
// eslint-disable-next-line no-unused-vars
import {collection, getDocs, query, orderBy, where} from "firebase/firestore";
import {getDownloadURL, ref} from "firebase/storage";

const getImageUrl = async (image) => {
  const imageRef = ref(storage, image)
  const imageUrl = await getDownloadURL(imageRef);
  return imageUrl;
}

const getOnePodcast = async (doc) => {
  let podcast = doc.data();
  podcast.id = doc.id
  if (podcast.header_image) {
    podcast.header_image = await getImageUrl(podcast.header_image);
  }
  return podcast;
}

export const getPodcasts = async () => {
  const podcastsRef = collection(db, "podcasts");
  //const querySnapshot = await getDocs(query(podcastsRef, where("status", "==", "published"), orderBy("created_on", "desc")));
  const querySnapshot = await getDocs(query(podcastsRef, orderBy("created_on", "desc")));
  const podcasts = await Promise.all(querySnapshot.docs.map(getOnePodcast));
  return podcasts;
}
