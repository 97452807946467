import {createRouter, createWebHistory} from 'vue-router'
import Home from "../views/Home.vue"
import Branding from "../views/Branding.vue"
import Strategic from "../views/Strategic.vue"
import Films from "../views/Films.vue"
import We from "../views/We.vue"
import Contact from "../views/Contact.vue"
import Greenhouse from "../views/Greenhouse.vue"
import BlogDetails from "../components/GreenhouseComponent/BlogDetails.vue"
import PodcastDetails from "../components/GreenhouseComponent/PodcastDetails.vue"
import Lab from "../components/LabComponent/LabBanner.vue"
import Cookies from "../components/CookiesComponent/CookiesPolicy.vue"
import Privacy from "../components/CookiesComponent/Policy.vue"
import ResourceDetailV2 from "@/views/ResourceDetailV2.vue";
import ResourcesV2 from "@/views/ResourcesV2.vue";
import CursoDeLaIdeaALaAccion from "@/views/CursoDeLaIdeaALaAccion.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/TheRootLab',
    name: 'Lab',
    component: Lab,
  },
  {
    path: "/branding/",
    name: "branding",
    component: Branding,
  },
  {
    path: "/estrategia-de-negocio/",
    name: "strategic",
    component: Strategic,
  },
  {
    path: "/wetheroot-films/",
    name: "Films",
    component: Films,
  },
  {
    path: "/home",
    name: "noname",
  },
  {
    path: "/nosotras",
    name: "We",
    component: We,
  },
  {
    path: "/cookies-policy/",
    name: "Cookies",
    component: Cookies,
  },
  {
    path: "/privacy-policy/",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/contacto/",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/resources",
    name: "ResourcesV2",
    component: ResourcesV2,
  },
  {
    path: "/curso-de-la-idea-a-la-accion",
    name: "CursoDeLaIdeaALaAccion",
    component: CursoDeLaIdeaALaAccion
  },
  {
    path: "/resources/detail/:slug?",
    name: "ProductResourceDetailV2",
    component: ResourceDetailV2,
    props: true
  },
  {
    path: "/el-invernadero/",
    name: "Greenhouse",
    component: Greenhouse,
    props: true
  },
  {
    path: '/el-invernadero/:id?',
    name: 'BlogDetails',
    component: BlogDetails,
  },
  {
    path: '/el-invernadero/:id?',
    name: 'PodcastDetails',
    component: PodcastDetails,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return { top: 0, behavior: 'smooth' }
  },
  routes
})
export default router
