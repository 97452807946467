<template>
  <BannerResourcesV2 />
  <ResourceDetail :id="id" :productResource="productResource" :slug="slug" />
  <ResourceDetailSeo :slug="slug"/>
  <Subscription />
</template>
<script>
import BannerResourcesV2 from "../components/ResourcesComponent/BannerResourcesV2.vue";
import ResourceDetail from "../components/ResourcesComponent/ResourceDetail.vue";
import ResourceDetailSeo from "../components/ResourcesComponent/ResourceDetailSeo.vue";
import Subscription from "../components/ResourcesComponent/Subscription";

export default {
  name: "ProductResourceDetailV2",
  components: {
    BannerResourcesV2,
    ResourceDetail,
    ResourceDetailSeo,
    Subscription,
  },
  props: ["id", "slug", "productResource"],
  methods:
  {
    async beforeCreate() {
      console.log(this);
    },
  },

};
</script>