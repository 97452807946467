<template>
  <div class="container">
    <div class="weare">{{ $t("strategic.breadcrumb") }}</div>
    <div class="site-name">
      {{ $t("strategic.strategic-name") }}
    </div>
    <div class="container-services">
      <h2 class="subtitle">{{ $t("strategic.strategic-sub") }}</h2>
      <ul>
        <li>{{ $t("strategic.strategic-item") }}</li>
        <li>{{ $t("strategic.strategic-item1") }}</li>
        <li>{{ $t("strategic.strategic-item2") }}</li>
        <li>{{ $t("strategic.strategic-item3") }}</li>
        <li>{{ $t("strategic.strategic-item4") }}</li>
      </ul>
    </div>
    <div class="container-btn">
      <router-link to="/contacto/">
        <button class="call-to-action new-btn">
          {{ $t("home.button-banner") }}
        </button>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "strategic",
};
</script>
<style scoped>
.container {
  padding: 50px 0 0px 50px;
  min-height: 650px;
  text-align: left;
  font-family: var(--secondary-font);
  background-image: url(../../assets/servicios/gradient-estrategia1.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}
.weare {
  color: var(--text);
  font-size: var(--font-size-regular);
  font-weight: 600;
  margin-bottom: 50px;
}

.subtitle {
  font-weight: 400;
  width: 65%;
}
ul {
  list-style-type: none;
  padding: 0;
  font-weight: 400;
  font-size: 17px;
}
.site-name {
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 60px;
  margin-top: -15px;
}

.call-to-action {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 17px;
  border-radius: 8px;
  border: 1.5px solid var(--text);
  padding: 9px 15px;
  background-color: white;
  margin-top: 30px;
  cursor: pointer;
}

.container-btn {
  display: flex;
  justify-content: center;
  margin-right: 90px;
}
.new-btn {
  cursor: pointer;
}

@media (max-width: 600px) {
  .site-name {
    font-size: 47px;
  }
  .container {
    padding: 40px 22px 30px 22px;
    background-size: 100%;
  }
  .subtitle {
    width: 100%;
  }
  .container-btn {
    margin-right: 0px;
  }
  .whatwedo {
    padding-top: 50px;
  }
}
</style>
