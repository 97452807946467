<template>
  <div class="blank-nav-bar-spacer"></div>
  <div class="nav-bar-container">
    <vue-navigation-bar :options="navbarOptions"
                        @vnb-item-clicked="vnbItemClicked"/>
  </div>
</template>
<script>

import brandImage from '../assets/Logo_WTR_negro_alargado.png'

export default {
    name: "Navbar",
    data() {

        return {
            showServicesMenu: false,
            navbarOptions: {
                elementId: 'main-navbar',
                isUsingVueRouter: true,
                mobileBreakpoint: 992,
                brandImagePath: '/',
                brandImage,
                brandImageAltText: 'brand-image',
                showBrandImageInMobilePopup: true,
                ariaLabelMainNav: 'Main Navigation',
                tooltipAnimationType: 'shift-away',
                tooltipPlacement: 'bottom',
                menuOptionsLeft: [
                    {
                        isLinkAction: true,
                        type: 'link',
                        text: this.$t("nav.we"),
                        path: '/nosotras',
                    },
                    {
                        type: 'link',
                        text: this.$t("nav.services"),
                        subMenuOptions: [
                            {
                                isLinkAction: true,
                                type: 'link',
                                text: this.$t("nav.business_strategy"),
                                path: '/estrategia-de-negocio',
                            },
                            {
                                isLinkAction: true,
                                type: 'link',
                                text: this.$t("nav.branding"),
                                path: '/branding',
                            },
                            {
                                isLinkAction: true,
                                type: 'link',
                                text: this.$t("nav.wtr_films"),
                                path: '/wetheroot-films',
                            },
                        ],
                    },
                    {
                        isLinkAction: true,
                        type: 'link',
                        text: this.$t("nav.foundation"),
                        path: '/TheRootLab',
                    },
                    {
                        isLinkAction: true,
                        type: 'link',
                        text: this.$t("nav.invernader"),
                        path: '/el-invernadero',
                    },
                    {
                        isLinkAction: true,
                        type: 'link',
                        text: this.$t("nav.resources"),
                        path: '/resources',
                    },
                    {
                        isLinkAction: true,
                        type: 'link',
                        text: this.$t("nav.curso-de-la-idea-a-la-accion"),
                        path: '/curso-de-la-idea-a-la-accion',

                    },
                    {
                        isLinkAction: true,
                        type: 'link',
                        text: this.$t("nav.contact"),
                        path: '/contacto',
                    },
                ],
                menuOptionsRight: [],
            },
        };
    },

    methods: {
        vnbItemClicked() {
        },
    },
};
</script>

<style>

.vnb {
  padding: 10px !important;
}
.vnb__menu-options__option {
    margin-right: 36px !important;
}

.vnb__popup__bottom__menu-options__option__link {
    text-transform: uppercase;
    padding: 12px 24px 6px;
}

.vnb__brand-image-wrapper__link {
    width: 125px;
    height: 65px;
}

.vnb__popup__bottom__sub-menu-options__option__link {
    text-align: start;
    text-transform: uppercase;
    padding: 10px 15px 0px 48px !important;
}

.vnb-image.vnb__brand-image-wrapper__link__image {
    width: 125px;
    max-height: 65px;
    height: 65px;
    padding: 5px 0px 0px 5px;
}

.vnb__popup__top {
    text-align: start;
}

.vnb-image.vnb__popup__top__image {
    width: 125px;
    max-height: 65px;
    height: 65px;
    padding: 5px 0px 0px 5px;
}

.vnb__menu-options__option__link {
    text-transform: uppercase;
}

nav {
    font-family: var(--secondary-font);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 9vh;
    position: relative;
}

.nav-bar-container {
    width: 100%;
    height: 9vh;
    position: fixed;
    top: 0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.blank-nav-bar-spacer {
  height: 9vh;
}
.vnb__menu-options.vnb__menu-options--left {
    margin-left: auto;
    justify-content: center;
}
</style>
