<template>
    <div v-if="status === 'loading'" class="container-loader">
        <InifiniteProgressLoader/>
    </div>
    <div v-else-if="status === 'loaded'">
        <img class="header" :src="article.header_image" alt="Blog image"/>
        <div class="container">
            <p class="title">
                <strong>{{ article.name }}</strong>
            </p>
            <br/>
            <!-- Aquí en el segundo blog, te falta el author, así que tienes que contemplar esta opción -->
            <p class="author">
                <strong
                >{{
                    article.author ? article.author.name : "Team Member"
                    }}.</strong
                >
            </p>
            <p class="margin">{{ article.author.role }}</p>
            <br/>
            <div
                    class="content"
                    v-for="(content, index) in article.content"
                    :key="index"
            >
                <div v-if="content.type === 'text'">
                    <VueMarkdown
                            :source="content.value.replaceAll('<br>','')"
                            :options="{ html: true }"
                    ></VueMarkdown>
                </div>
                <div v-else-if="content.type === 'images'"
                     class="image-container">
                    <img class="image-box" :src="content.value"/>
                </div>
            </div>
        </div>
    </div>
    <div v-else>Article not found</div>
</template>
<script>
import {getArticle} from "../../blog";
import VueMarkdown from "vue-markdown-render";
import InifiniteProgressLoader
    from "@/components/GreenhouseComponent/InifiniteProgressLoader";

export default {
    name: "BannerGreenhouse",
    components: {
        InifiniteProgressLoader,
        VueMarkdown,
    },
    data() {
        return {
            article: {},
            status: "loading",
            id: "",
        };
    },
    async beforeCreate() {
        this.id = this.$route.params.id;
        this.article = await getArticle(this.id);
        if (this.article) {
            console.log(this.article);
            this.status = "loaded";
        } else this.status = "error";
    },
};

</script>
<style scoped>

.image-container {
    padding: 30px;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
}

.image-box {
    width: 100%;
}

.header {
    width: 100%;
}

.container {
    padding: 30px 60px;
    text-align: left;
    font-size: 18px;
}

.title {
    font-size: 30px;
    text-align: center;
    margin-top: 20px;
}

.author {
    max-width: 80%;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
}

.margin {
    max-width: 80%;
    margin-top: -20px;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
}

.content {
    max-width: 80%;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
}

.content:deep(a) {
    text-decoration: underline;
}

.content:deep(a:hover) {
    color: var(--green);
    text-decoration: underline;
}

@media (max-width: 600px) {
    .container {
        padding: 30px;
    }

    .content {
        max-width: 90%;
        text-align: justify;
        margin-left: auto;
        margin-right: auto;
    }

    .author {
        max-width: 90%;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: justify;
    }

    .margin {
        max-width: 90%;
        margin-top: -20px;
        text-align: justify;
        margin-left: auto;
        margin-right: auto;
    }
}

.container-loader {
    padding: 50px;
}
</style>
