<template>
  <div class="blog-article-container">
    <router-link
      :to="{
        name: 'BlogDetails',
        params: { id: article.id, article: article },
        props: true,
      }"
    >
      <!-- Esto debería ser un componente -->
      <img class="pax-pic" :src="article.header_image" alt="Blog image" />

      <p>
        <strong>{{ article.name }}</strong>
      </p>
      <br />
      <p>Autor: {{ article.author ? article.author.name : "Team Member" }}.</p>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "BlogCard",
  props: {
    article: Object,
  },
};
</script>

<style scoped>
.pax-pic {
  width: 270px;
}

.blog-article-container {
  width: 100%;
  text-align: center;
  margin: 70px 0;
  margin-bottom: 20px;
}
</style>
