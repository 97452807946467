<template>
  <div class="container">
    <router-link
        :to="{
        name: 'ProductResourceDetailV2',
        params: { slug: productResource.slug },
        props: true,
      }"
    >
      <!-- Esto debería ser un componente -->
      <img class="pax-pic" :src="productResource.images[0]" alt="image" />

      <p>
        <strong><span class="resource-title">{{ productResource.name }}</span></strong>
      </p>
      <p>
        <span class="resource-subtitle">{{ productResource.subtitle }}</span>
      </p>
      <p>
        <strong><span class="resource-price">- {{ productResource.price ? productResource.price: "" }}€ -</span></strong>
      </p>
      <br />
        <strong><span class="resource-tags">{{ (productResource.tags ?? []).join(" #") }}</span></strong>
    </router-link>
  </div>
</template>
<script>
export default {
  name: "ProductResourceCard",
  props: ["productResource"]
};
</script>

<style scoped>
.container {
  padding: 14px;
  width: 320px;
  text-align: center;
  margin: 32px;
}
.container:hover {
  box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.5),
  0px 2px 4px rgba(0,0,0,0.1),
  0px 4px 8px rgba(0,0,0,0.1),
  0px 8px 16px rgba(0,0,0,0.1);

}

.resource-title {
  font-family: var(--secondary-font);
  font-size: 18px;
  max-lines: 1;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
}
.resource-subtitle {
  font-family: var(--secondary-font);
  font-size: 14px;
}
.resource-price {
  font-family: var(--secondary-font);
  font-size: 14px;
}
.resource-tags {
  font-family: var(--secondary-font);
  font-size: 14px;
  color: #888;
  font-weight: 700;

}
.pax-pic {
  width: 240px;
}
</style>
