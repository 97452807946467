<template>
  <h2 class="breadcrumb">{{ $t("films.breadcrumb2") }}</h2>
  <div class="container parent">
    <div class="div1">
      <a href="https://www.youtube.com/watch?v=iE7uO2rzFfk&list=PLiHfVH9T1gfNBMFVB-i5h5h-cXVH_RoQF&index=1">
        <img src="../../assets/1.png" alt="ESCLEROSIS MULTIPLE" />
      </a>
    </div>
    <div class="div1">
      <a href="https://www.youtube.com/watch?v=8I3Kjkh6jO4&list=PLiHfVH9T1gfNBMFVB-i5h5h-cXVH_RoQF&index=6">
        <img
          src="../../assets/2.png"
          alt="ESCLEROSIS MULTIPLE"
        />
      </a>
    </div>
    <div class="div1">
      <a href="https://www.youtube.com/watch?v=GXUYonmeINI&list=PLiHfVH9T1gfNBMFVB-i5h5h-cXVH_RoQF&index=5">
        <img
          src="../../assets/3.png"
          alt="ESCLEROSIS MULTIPLE"
        />
      </a>
    </div>
    <div class="div1">
      <a href="https://www.youtube.com/watch?v=mGukInpRlaM&list=PLiHfVH9T1gfNBMFVB-i5h5h-cXVH_RoQF&index=12">
        <img src="../../assets/4.png" alt="ESCLEROSIS MULTIPLE" />
      </a>
    </div>
    <div class="div1">
      <a href="https://www.youtube.com/watch?v=WIkRZO4ziWk">
        <img
          src="../../assets/servicios/unitedway.jpg"
          alt="UNITED WAY VIDEO"
        />
      </a>
    </div>
    <div class="div1">
      <a href="https://www.youtube.com/watch?v=wwYspojDyG4">
        <img src="../../assets/servicios/holis.jpg" alt="HOLIS VIDEO" />
      </a>
    </div>
  </div>
  <div class="call-to-action">
    <h3 class="sub">{{ $t("films.action") }}</h3>
    <h3 class="title">
      {{ $t("films.explain") }}
    </h3>
    <router-link to="/contacto/" @click="scrollToTop">
      <button class="btn">{{ $t("films.btn") }}</button>
    </router-link>
  </div>
</template>
<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
.breadcrumb {
  font-size: 17px;
  font-family: var(--secondary-font);
  text-align: left;
  margin-top: 70px;
  margin-bottom: 80px;
  padding-left: 50px;
  font-weight: 600;
}
.parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  align-items: center;
  padding: 0 50px;
}

.div1 {
  text-align: left;
  box-sizing: border-box;
}

img {
  width: 100%;
  height:350px;
  cursor: pointer;
}
img:hover {
  transform: scale(1.02);
}

.call-to-action {
  padding-top: 30px;
  margin: 0 auto;
  width: 60%;
}

.title {
  font-size: 25px;
  font-weight: 300;
}
.sub {
  font-size: 35px;
  font-family: var(--secondary-font);
  font-weight: 300;
}
.btn {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 17px;
  border-radius: 8px;
  border: 1.5px solid black;
  padding: 9px 15px;
  background-color: white;
  margin-top: 10px;
  cursor: pointer;
}
@media (max-width: 600px) {
  .parent {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 25px;
    align-items: center;
    padding: 0 50px;
  }
  .breadcrumb {
    margin-bottom: 75px;
    padding-left: 30px;
  }
  .title {
    font-size: 20px;
  }
  .call-to-action {
    padding-bottom: 30px;
    width: 90%;
  }
}
</style>
