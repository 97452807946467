<template>
  <button :class="buttonSize">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "WeTheRootGreenButton",
  props: {
    buttonSize: {
      type: String,
      default: "small",
    },
  },
};
</script>
<style scoped>
.small {
  font-size: 14px;
  padding: 5px 10px;
  margin-right: 5px;
}

.large {
  font-size: 17px;
  padding: 9px 15px;
  margin: 30px 0;
}

button {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 14px;
  border-radius: 16px;
  border: 1.5px solid #687c6c;
  padding: 5px 10px;
  background-color: #687c6c;
  margin-right: 5px;
  cursor: pointer;
}
button:hover {
  background-color: #D0A98F;
}

</style>
