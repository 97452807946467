<template>
  <div class="test container" :scroll="handleScroll">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2439.36 421.54">
      <g id="Layer_2" data-name="Layer 2">
        <g
          id="Layer_2-2"
          data-name="Layer 2"
          fill="transparent"
          stroke=" #414042"
          stroke-miterlimit="10"
          stroke-width="3"
        >
          <path
            class="path"
            d="M.23,420.83c232.69-73.19,482.16-62,726.05-57s496.14,1.44,719.05-97.61c75.41-33.5,147.44-78.69,199.3-142.87,14.31-17.73,27.34-37.66,30.46-60.23S1668.94,15,1648.43,5.06C1622.32-7.57,1591,9.47,1572.1,31.5,1530.84,79.67,1524.45,154.58,1557,209s101.47,84.41,163.45,71c42.65-9.25,78.95-36,116.67-58a570.61,570.61,0,0,1,267.17-76.79c113.69-3.7,237.18,24.67,334.74-33.81"
            pathLength="1"
          />
        </g>
      </g>
    </svg>
    <div class="flex-2">
      <router-link to="/" @click="scrollToTop">
        <img
          class="logo-footer"
          src="../assets/branding/logo-footer.png"
          alt="logo wtr"
        />
      </router-link>
      <p class="info-footer">
        <a
          target="_blank"
          href="https://www.instagram.com/we.theroot/?hl=es&utm_source=Web&utm_medium=Footer"
        >
          <img
            class="logorrss"
            src="../assets/SVG/SVG/ig.svg"
            alt="instagram logo"
          />
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/wetheroot/?utm_source=Web&utm_medium=Footer&utm_campaign=Linkedin"
        >
          <img
            class="logorrss"
            src="../assets/SVG/SVG/linkedin.svg"
            alt="linkedin logo"
          />
        </a>
      </p>
      <p class="info-footer">hola@wetheroot.com</p>

      <div  class="sannas">
        <p class="margin-sannas">
Somos parte de    
      </p>
      <a href="https://www.sannas.eu/" target="_blank">
      <img class="img-footer" src="../assets/sannas.png" alt="sannas">
    </a>
    </div>
    </div>
  </div>
  <br/>
  <div class="footer-link">
  <p class="cookies"><router-link to="privacy-policy"> Política de Privacidad </router-link></p>
  <p class="cookies"><router-link to="cookies-policy"> Política de Cookies </router-link></p>
</div>
<p class="copy">© 2023</p>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      var reveals = document.querySelectorAll(".path");
      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;
        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
.cookies {
  margin-right: 10px;
}
.footer-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.margin-sannas {
  font-size: 14px;
margin-right: 10px;
}
.sannas {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.img-footer {
  width: 120px;
  padding: 20px 0px;
}
svg {
  margin-top: 20px;
}
.flex-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  margin-right: 55px;
  margin-top: -60px;
  font-family: var(--secondary-font);
}
.path {
}
.path.active {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 2.5s ease alternate forwards;
  animation-delay: 0.3s;
  animation-iteration-count: 1;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.footer-img {
  width: 100%;
}
.logo-footer {
  width: 200px;
}
.info-footer {
  margin: 2px 5px;
}
.flex {
  width: 200px;
}

.logorrss {
  width: 15px;
  margin-right: 5px;
}

/* mediaqueries */
@media (max-width: 600px) {
  .container {
    height: 250px;
  }
  .flex-2 {
    margin-top: 20px;
    margin-right: 15px;
  }
}
</style>
