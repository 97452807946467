<template>
  <h2 class="breadcrumb">{{ $t("strategic.breadcrumb-second") }}</h2>
  <div class="container">
    <div class="info-services">
      <h2>
        {{ $t("strategic.work-title") }} <br />
        {{ $t("strategic.work-title1") }}
      </h2>
      <p>
        {{ $t("strategic.work-text-first") }}
      </p>
    </div>

    <hr />

    <div class="info-services">
      <h2>{{ $t("strategic.work-title-first") }}</h2>
      <p>
        {{ $t("strategic.work-text-second") }}
      </p>
    </div>

    <hr />

    <div class="info-services">
      <h2>
        {{ $t("strategic.work-title-second") }} <br />
        {{ $t("strategic.work-title-second0") }}
        {{ $t("strategic.work-title-second1") }}
      </h2>
      <p>
        {{ $t("strategic.work-text-third") }}
      </p>
    </div>
    <hr />

    <div class="info-services">
      <h2>
        {{ $t("strategic.work-title-third") }}<br />
        {{ $t("strategic.work-title-third1") }}
      </h2>
      <p>
        {{ $t("strategic.work-text-fourth") }}
      </p>
    </div>

    <hr />

    <div class="info-services">
      <h2>
        {{ $t("strategic.work-title-fourth") }}<br />
        {{ $t("strategic.work-title-fourth1") }}
      </h2>
      <p>
        {{ $t("strategic.work-text-fifth") }}
      </p>
    </div>
    <hr />
  </div>
  <div class="background">
    <div class="testimony">
      <img
        src="../../assets/justyne-modifiedcircle.png"
        alt="justyne testimonio"
      />

      <div class="container-client">
        <div class="comillas">“</div>
        <p class="client-text">
          We The Root is leading our communication and understanding of our
          audience as well as supporting our ideation sessions. We are glad to
          have such a partner since our way of working requires agility and
          flexibility while communicating our value to others - especially
          online. Honest and open conversations are something that We The Root
          represents while working together with a client”.
        </p>
        <div class="ceo-testimony">
          <h2 class="client-name">Justyna Turek</h2>
          <p class="client-text">CEO We Are Holis</p>
        </div>
      </div>
    </div>
    <div class="call-to-action">
      <h3 class="title">
        {{ $t("strategic.strategic-call-text") }}<br />
        {{ $t("strategic.strategic-call-text1") }} <br />
        {{ $t("strategic.strategic-call-text2") }}
      </h3>
      <router-link to="/contacto/" @click="scrollToTop">
        <button class="btn">{{ $t("home.button-banner-new") }}</button>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "SecondBanner",
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
.background {
  background-image: url(../../assets/servicios/gradient-estrategia2.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 10%;
  min-height: 600px;
  padding: 0 0 80px 0;
}
.container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.breadcrumb {
  font-size: 17px;
  font-family: var(--secondary-font);
  text-align: left;
  margin-top: 40px;
  padding-left: 50px;
  font-weight: 600;
}
h2 {
  font-family: var(--secondary-font);
  font-weight: 400;
  font-size: 35px;
  margin-bottom: -10px;
}
.symb {
  font-size: 45px;
  font-weight: 500;
}
.info-services {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: 0 auto;
  min-height: 160px;
}
.strategic,
.branding,
.films {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.call-to-action {
  margin-top: 90px;
}
h2 {
  width: 50%;
  margin: 0;
}
p {
  width: 50%;
  margin: 0;
}
hr {
  width: 93%;
}

.title {
  font-size: 70px;
  font-weight: 300;
  margin-top: 160px;
}
.btn {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 17px;
  border-radius: 8px;
  border: 1.5px solid black;
  padding: 9px 15px;
  background-color: white;
  margin-top: 10px;
  cursor: pointer;
}

/* marta bermejo */
.testimony {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 90px;
}
.comillas {
  font-size: 90px;
  margin-bottom: -30px;
}

.container-client {
  padding-top: 50px;
  padding-left: 30px;
  width: 50%;
}
.client-name {
  font-size: 20px;
  margin: 0;
  font-weight: 500;
}
.client-text {
  font-size: 18px;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
}
img {
  width: 20%;
  height: 20%;

  padding-top: 50px;
}
@media (max-width: 600px) {
  .strategic,
  .branding,
  .films {
    justify-content: space-between;
  }
  .btn {
    margin-top: 40px;
  }
  .info-services {
    width: 95%;
    margin: 10px;
    flex-direction: column;
    align-items: center;
  }
  .testimony {
    flex-direction: column;
  }
  .breadcrumb {
    padding-left: 30px;
  }
  .container-client {
    width: 90%;
    padding: 0 30px;
  }

  hr {
    background-color: var(--text);
    width: 90%;
  }
  h2 {
    width: 90%;
    margin: 0;
  }
  p {
    width: 90%;
    margin: 0;
  }
  img {
    width: 40%;
  }
  h3 {
    margin: 20px;
  }
  .title {
    font-size: 50px;
  }
  .call-to-action {
    margin: 110px 0 40px 0;
  }
}
</style>
