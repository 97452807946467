<template>
  <div class="container">
    <div class="animation">
      <div class="weare">{{ $t("home.banner") }}</div>
      <div class="site-name">
        {{ $t("home.site-name") }}
      </div>
    </div>

    <div class="container-btn">
      <router-link to="/contacto/">
        <WeTheRootButton buttonSize="large">
          {{ $t("home.button-banner") }}
        </WeTheRootButton>
      </router-link>
    </div>
    <div class="whatwedo">
      <h2 class="breadcrumb">{{ $t("home.breadcrumb-first") }}</h2>
      <p class="text">
        {{ $t("home.main-text") }} <u>{{ $t("home.main-text1") }}</u> <br />
        {{ $t("home.main-second") }}
        <strong
          ><i>{{ $t("home.main-second1") }}</i></strong
        ><br />
        {{ $t("home.main-third") }}
      </p>
    </div>
  </div>
</template>
<script>
import WeTheRootButton from "@/components/UiComponents/WeTheRootButton";
export default {
  name: "HeroBanner",
  components: {WeTheRootButton}
};
</script>
<style scoped>
.container {
  padding: 80px 0 20px 0px;
  min-height: 700px;
  text-align: left;
  font-family: var(--secondary-font);
  background-image: url(../../assets/gradiente-banner.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
}

.site-name {
  margin: 0 auto;
  animation: zoom-in-zoom-out 3s ease 1;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.whatwedo {
  padding-left: 50px;
}
.weare {
  color: var(--text);
  font-size: 93px;
  margin: 0 auto;
  font-weight: 300;
  text-align: center;
}
.site-name {
  font-weight: 800;
  margin: 0 auto;
  letter-spacing: -5px;
  font-size: 95px;
  text-align: center;
}
.container-btn {
  text-align: center;
}
.whatwedo {
  padding-top: 90px;
}

.breadcrumb {
  font-size: 17px;
  padding-bottom: 10px;
}
.text {
  font-family: var(--principal-font);
  font-weight: 300;
  font-size: 40px;
  padding-bottom: 10px;
}
hr {
  width: 95%;
  background-color: var(--text);
}

.call-to-action {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 17px;
  border-radius: 8px;
  border: 1.5px solid var(--text);
  padding: 9px 15px;
  background-color: white;
  margin-top: 30px;
  cursor: pointer;
}
.new-btn {
  cursor: pointer;
}
.path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 10s ease alternate forwards;
  animation-delay: 1s;
  animation-iteration-count: 1;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@media (max-width: 600px) {
  .container {
    padding: 15px;
    background-size: 100%;
    margin: 70px 10px 70px 10px;
    background-position: center;
  }
  .whatwedo {
    padding-top: 100px;
    padding-left: 5px;
  }
}
</style>
