<template>
  <div class="container">
    <div class="info-services">
      <h2>NAMING</h2>
      <p>
        {{ $t("branding.work-text") }}
      </p>
    </div>

    <hr />

    <div class="info-services">
      <h2>
        {{ $t("branding.work-title0") }} <br />
        {{ $t("branding.work-title00") }}
      </h2>
      <p>{{ $t("branding.work-text1") }}</p>
    </div>

    <hr />
    <div class="info-services">
      <h2>
        {{ $t("branding.work-title6") }} <br />
        {{ $t("branding.work-title7") }} <br />
        {{ $t("branding.work-title8") }}
      </h2>
      <p>{{ $t("branding.work-text-6") }}</p>
    </div>

    <hr />

    <div class="info-services">
      <h2>BRANDING/ <br />(RE)BRANDING</h2>
      <p>
        {{ $t("branding.work-text-4") }}
      </p>
    </div>
    <hr />

    <div class="info-services">
      <h2>
        {{ $t("branding.work-title") }} <br />
        {{ $t("branding.work-title_") }}
      </h2>
      <p>
        {{ $t("branding.work-text2") }}
      </p>
    </div>
    <hr />
    <div class="info-services">
      <h2>LOOK&FEEL<br />{{ $t("branding.work-title1") }}</h2>
      <p>
        {{ $t("branding.work-text-3") }}
      </p>
    </div>

    <hr />
  </div>
  <div class="testimony">
    <img src="../../assets/martabermejo-modified.png" alt="marta testimonio" />

    <div class="container-client">
      <div class="comillas">“</div>
      <p class="client-text">
        {{ $t("home.testimony-marta") }}
      </p>
      <div class="ceo-testimony">
        <h2 class="client-name">Marta Bermejo</h2>
        <p class="client-text">{{ $t("home.testimony-role1") }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SecondBanner",
};
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
h2 {
  font-family: var(--secondary-font);
  font-weight: 400;
  font-size: 35px;
  margin-bottom: -10px;
}
.symb {
  font-size: 45px;
  font-weight: 500;
}
.info-services {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: 0 auto;
  min-height: 120px;
}
.strategic,
.branding,
.films {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.call-to-action {
  padding: 60px 30px 140px 30px;
}
h2 {
  width: 50%;
  margin: 0;
}
p {
  width: 50%;
  margin: 0;
}
hr {
  width: 93%;
}

.title {
  font-size: 70px;
  font-weight: 300;
}
.btn {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 17px;
  border-radius: 8px;
  border: 1.5px solid black;
  padding: 9px 15px;
  background-color: white;
  margin-top: 10px;
  cursor: pointer;
}

/* marta bermejo */
.testimony {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 90px;
}
.comillas {
  font-size: 90px;
  margin-bottom: -30px;
}

.container-client {
  padding-top: 50px;
  padding-left: 30px;
  width: 50%;
}
.client-name {
  font-size: 20px;
  margin: 0;
  font-weight: 500;
}
.client-text {
  font-size: 18px;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
}
img {
  width: 20%;
  height: 20%;
  padding-top: 50px;
}
@media (max-width: 600px) {
  .strategic,
  .branding,
  .films {
    justify-content: space-between;
  }
  .info-services {
    padding-left: 0px;
    width: 92%;
    margin: 20px;
    align-items: center;
    flex-direction: column;
  }
  hr {
    background-color: var(--text);
    width: 90%;
  }
  h2 {
    width: 90%;
    margin: 0;
  }
  p {
    width: 90%;
    margin: 0;
  }
  img {
    width: 40%;
  }
  .title {
    font-size: 50px;
  }
  .container-client {
    padding-left: 10px;
  }
}
</style>
