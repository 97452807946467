<template>
  <div class="scroller">
    <section class="container">
      <div class="testimony">
        <div class="comillas">“</div>
        <p>
          We The Root is leading our communication and understanding of our
          audience as well as supporting our ideation sessions. We are glad to
          have such a partner since our way of working requires agility and
          flexibility while communicating our value to others - especially
          online. Honest and open conversations are something that We The Root
          represents while working together with a client”
        </p>
        <div class="img-text">
          <img
            src="../../assets/Logos-clientes/justyne.jpeg"
            alt="justyna testimony"
          />
          <div class="ceo-testimony">
            <h2>Justyna Turek</h2>
            <p>CEO We Are Holis</p>
          </div>
        </div>
      </div>
      <div class="testimony">
        <div class="comillas">“</div>
        <p>
          {{ $t("home.testimony-marta") }}
        </p>
        <div class="img-text">
          <img
            src="../../assets/Logos-clientes/martabermejo.jpeg"
            alt="marta testimonio"
          />
          <div class="ceo-testimony">
            <h2>{{ $t("home.testimony-name1") }}</h2>
            <p>{{ $t("home.testimony-role1") }}</p>
          </div>
        </div>
      </div>
    </section>
    <section class="call-to-action animation">
      <h3 class="title">
        {{ $t("home.call-to-action-home") }}
        <u>{{ $t("home.call-to-action-solutions") }}</u>
        {{ $t("home.call-to-action-solutions1") }}
      </h3>
      <router-link to="/contacto/" @click="scrollToTop">
        <button class="btn">{{ $t("home.button-banner") }}</button>
      </router-link>
    </section>
  </div>
</template>
<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
html {
}
.container {
  min-height: 500px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  background-image: url(../../assets/gradiente-testimonios.svg);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 20px;
}
.animation {
  margin: 0 auto;
  animation: zoom-in-zoom-out 2s ease-in 1;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.15, 1.15);
  }
  100% {
    transform: scale(1, 1);
  }
}

.testimony {
  width: 30%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  scroll-snap-align: center;
}
.comillas {
  font-size: 90px;
  margin-bottom: -30px;
}
h2 {
  margin: 0;
}
p {
  font-size: 20px;
  margin: 0;
}
img {
  width: 25%;
  border-radius: 50%;
}
.img-text {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ceo-testimony {
  margin-left: 20px;
}
.title {
  font-size: 70px;
  font-weight: 300;
  width: 80%;
  margin: 220px auto 40px auto;
}
.btn {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 17px;
  border-radius: 8px;
  border: 1.5px solid black;
  padding: 9px 15px;
  background-color: white;
  margin-top: 10px;
  cursor: pointer;
}
.call-to-action {
  margin-bottom: 240px;
}
@media (max-width: 600px) {
  .container {
    flex-direction: column;
    padding: 20px 30px;
  }
  .testimony {
    width: 100%;
  }
  .img-text {
    margin-bottom: 30px;
  }
  .title {
    width: 90%;
    font-size: 50px;
    margin: 190px 5px 50px 20px;
  }
  .call-to-action {
    min-height: 500px;
    margin-bottom: 180px;
  }
}
</style>
