<template>
  <div class="container">
    <p class="title">{{ $t("we.call-to-action1") }}</p>
    <p class="title">
      <u>{{ $t("we.call-to-action1-2") }}</u>
    </p>
    <p class="title">{{ $t("we.call-to-action1-3") }}</p>

    <p class="title margin">
      {{ $t("we.call-to-action2") }} <u>{{ $t("we.call-to-action2-1") }}</u>
    </p>
    <p class="title">{{ $t("we.call-to-action2-2") }}</p>
  </div>
</template>
<script></script>
<style scoped>
.container {
  background-image: url(../../assets/asset2.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: bottom;
  padding-top: 50px;
  min-height: 600px;
}
.margin {
  padding-top: 50px;
}
.title {
  font-family: var(--secondary-font);
  font-size: 32px;

  font-weight: 300;
  width: 70%;
  margin: 0 auto;
}
p {
  margin: 0;
}

@media (max-width: 600px) {
  .title {
    width: 90%;
    font-size: 30px;
  }
  .container {
    background-position: top;
  }
}
</style>
