<template>
    <div class="training-course-container">
      <iframe :src="iframe.src" ref="frame" @load="load" v-show="iframe.loaded"></iframe>
      <div class="container-loader" v-if="!iframe.loaded">
        <InifiniteProgressLoader/>
      </div>
    </div>
</template>
<script>

import InifiniteProgressLoader from "@/components/GreenhouseComponent/InifiniteProgressLoader.vue";

export default {
  name: "CursoDeLaIdeaALaAccion",
  components: {InifiniteProgressLoader},
  data() {
    return {
      iframe: {
        src: 'https://curso-wetheroot.netlify.app/',
        //src: 'https://www.therootlab.org/curso-online-de-comunicacion-pasa-de-la-idea-a-la-accion/',
        loaded: false
      }
    }
  },
  methods: {
    load: function(){
      this.iframe.loaded = true;
    }
  }
};
</script>

<style scoped>
.training-course-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
iframe {
  width: 100%;
  height: 100%;
}
</style>