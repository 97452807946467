<template>
  <div class="call-to-action">
    <h2 class="title">
      {{ $t("branding.call-subs") }} <br />
      {{ $t("branding.call-subs2") }} <br />
      {{ $t("branding.call-subs3") }}
    </h2>
    <router-link to="/contacto/" @click="scrollToTop">
      <button class="btn">{{ $t("home.button-banner-new") }}</button>
    </router-link>
  </div>
</template>
<script>

export default {
  name: "FormContact",
  data() {
    return {
      formSent: false,
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    }
  },
};
</script>
<!--End mc_embed_signup-->
<style scoped type="text/css">
.call-to-action {
  min-height: 500px;
  width: 100%;
  font-size: 40px;
  font-family: var(--secondary-font);
  background-image: url(../../assets/servicios/gradient-branding2.svg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 200;
}
.btn {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 17px;
  border-radius: 8px;
  border: 1.5px solid black;
  padding: 9px 15px;
  background-color: white;
  cursor: pointer;
  margin: 50px 0;
}
h2 {
  font-weight: 200;
  margin: 90px 20px 0px 20px;
}

@media (max-width: 600px) {
  .call-to-action {
    width: 100%;
  }
  h2 {
    margin: 90px 20px 0px 20px;
  }
  .btn {
    font-family: var(--secondary-font);
    font-weight: 500;
    font-size: 17px;
    border-radius: 8px;
    border: 1.5px solid black;
    padding: 9px 15px;
    background-color: white;
    margin: 60px 0;
    cursor: pointer;
  }
}
</style>
