<template>
  <p class="breadcrumb">{{ $t("we.breadcrumb-team") }}</p>
  <div class="container-founders">
    <div class="founder">
      <a href="https://www.linkedin.com/in/andreadosanjos/">
        <img
          class="pax-pic"
          src="../../assets/Andrea.webp"
          alt="Andrea Dos Anjos"
        />
        <h1>{{ $t("we.team-member-name") }}</h1>
      </a>
      <p class="role">{{ $t("we.team-role") }}</p>
      <p>{{ $t("we.team-bio") }}</p>
      <p class="mail">andrea@wetheroot.com</p>
    </div>
    <div class="founder">
      <a href="https://www.linkedin.com/in/beatrizseguragarcia/">
        <img
          class="pax-pic"
          src="../../assets/Beatriz.webp"
          alt="Beatriz Segura"
        />
        <h1>{{ $t("we.team-member-name1") }}</h1>
      </a>
      <p class="role">{{ $t("we.team-role1") }}</p>
      <p>{{ $t("we.team-bio1") }}</p>
      <p class="mail">beatriz@wetheroot.com</p>
    </div>
  </div>
  <div class="container-workers">
    <div class="worker">
      <a href="https://www.linkedin.com/in/monica-dos-anjos/">
        <img class="pax-pic" src="../../assets/Monica.jpg" alt="Monica" />
        <h1>{{ $t("we.team-member-name3") }}</h1>
      </a>
      <p class="role">{{ $t("we.team-role3") }}</p>
      <p>{{ $t("we.team-bio3") }}</p>
      <p class="mail">monica@wetheroot.com</p>
    </div>
    <div class="worker">
      <a href="https://www.linkedin.com/in/valentinamontescastillo/">
        <img
          class="pax-pic"
          src="../../assets/Valentina.jpg"
          alt="Valentina Montes"
        />
        <h1>{{ $t("we.team-member-name4") }}</h1>
      </a>
      <p class="role">{{ $t("we.team-role4") }}</p>
      <p>{{ $t("we.team-bio4") }}</p>
      <p class="mail">valentina@wetheroot.com</p>
    </div>
  </div>
  <p class="breadcrumb collabs-crumb">{{ $t("we.breadcrumb-collabs") }}</p>
  <div class="container-collabs">
    <div class="collab">
      <a
        href="https://www.linkedin.com/in/aurora-malav%C3%A9-marcano-99655bb6/"
      >
        <img class="pax-pic" src="../../assets/Aurora.jpg" alt="Aurora" />
        <h1>{{ $t("we.collab-name") }}</h1>
      </a>
      <p class="role">{{ $t("we.collab-role") }}</p>
    </div>
    <div class="collab">
      <a href="https://www.linkedin.com/in/sten-ritterfeld/">
        <img class="pax-pic" src="../../assets/Sten.jpg" alt="Sten" />
        <h1>{{ $t("we.collab-name2") }}</h1>
      </a>
      <p class="role">{{ $t("we.collab-role2") }}</p>
    </div>
    <div class="collab">
      <a href="https://www.linkedin.com/in/soleny-yumare/">
        <img class="pax-pic" src="../../assets/Soleny.jpg" alt="Soleny" />
        <h1>{{ $t("we.collab-name3") }}</h1>
      </a>
      <p class="role">{{ $t("we.collab-role3") }}</p>
    </div>
  </div>
  <div class="container-collabs">
    <div class="collab">
      <a href="https://www.linkedin.com/in/valentina-demori-79093474/">
        <img class="pax-pic" src="../../assets/Marta2.png" alt="Marta" />
        <h1>{{ $t("we.collab-name4") }}</h1>
      </a>
      <p class="role">{{ $t("we.collab-role4") }}</p>
    </div>
    <div class="collab">
      <img class="pax-pic" src="../../assets/Jesus.png" alt="Jesus" />
      <h1>{{ $t("we.collab-name5") }}</h1>
      <p class="role">{{ $t("we.collab-role5") }}</p>
    </div>
    <div class="collab">
      <a href="https://www.linkedin.com/in/jesusantoniozambranocenteno/">
        <img
          class="pax-pic"
          src="../../assets/valendemori.png"
          alt="Valentina"
        />
        <h1>{{ $t("we.collab-name6") }}</h1>
      </a>
      <p class="role">{{ $t("we.collab-role6") }}</p>
    </div>
    <div class="collab">
      <img class="pax-pic" src="../../assets/Soledad.png" alt="Soledad" />
      <h1>{{ $t("we.team-member-name5") }}</h1>
      <p class="role">{{ $t("we.team-role5") }}</p>
    </div>
  </div>
  <router-link to="/contacto/" @click="scrollToTop">
    <button class="btn">HABLEMOS</button>
  </router-link>
</template>
<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
.breadcrumb {
  font-weight: 900;
  padding: 20px;
  font-family: var(--secondary-font);
}
.container-founders,
.container-workers,
.container-collabs {
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.btn {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 17px;
  border-radius: 8px;
  border: 1.5px solid black;
  padding: 9px 15px;
  background-color: white;
  cursor: pointer;
  margin: 50px 0;
}
.pax-pic {
  width: 220px;
}
.founder,
.worker,
.collab {
  width: 20%;
  margin-right: 30px;
}
h1 {
  font-family: var(--secondary-font);
  margin-bottom: 0;
  font-size: 20px;
  text-align: center;
}
.role {
  font-family: var(--secondary-font);
  margin-bottom: 20px;
}
.role {
  text-align: center;
}
.mail {
  font-family: var(--secondary-font);
  margin: 20px 0;
  font-weight: 300;
}
.collabs-crumb {
  margin-top: 40px;
}
@media (max-width: 600px) {
  .container-founders,
  .container-workers,
  .container-collabs {
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
  }
  .founder,
  .worker,
  .collab {
    width: 90%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
  }
  .breadcrumb {
    padding: 70px 0px 30px 0px;
  }
  .collabs-crumb {
    margin-top: 0px;
  }
}
@media (min-width: 768px) and (max-width: 830px) {
  .founder,
  .worker,
  .collab {
    width: 70%;
  }
  .container-founders,
  .container-workers,
  .container-collabs {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.container-workers, .container-collabs, .collabs-crumb{
  display: none!important;
}
</style>
