<template>
  <div class="container">
    <div class="weare">{{ $t("films.breadcrumb") }}</div>
    <h2>WE THE ROOT</h2>
    <div class="site-name">FILMS</div>
    <div class="container-services">
      <h3 class="subtitle">
        {{ $t("films.desc") }}
        {{ $t("films.desc1") }}
      </h3>
    </div>
    <div class="container-btn">
      <router-link to="/contacto/">
        <button class="call-to-action new-btn">
          {{ $t("films.btn") }}
        </button>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: "strategic",
};
</script>
<style scoped>
.container {
  min-height: 450px;
  text-align: left;
  font-family: var(--secondary-font);
  background-size: cover;
  background-image: url(../../assets/Videos/teaser-sinsubtis.gif);
  background-repeat: no-repeat;
  color: white;
  padding: 80px 50px;
  text-shadow: 2px 2px 2px black;
}
.weare {
  padding-top: 50px;
  color: white;
  font-size: var(--font-size-regular);
  font-weight: 600;
}
h2 {
  margin: 30px 0 -20px 0;
}
.subtitle {
  font-weight: 400;
  width: 60%;
}
ul {
  list-style-type: none;
  padding: 0;
  font-weight: 400;
  font-size: 17px;
}
.site-name {
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 60px;
}

.call-to-action {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 17px;
  border-radius: 8px;
  border: 1.5px solid var(--text);
  padding: 9px 15px;
  background-color: white;
  margin-top: 30px;
  cursor: pointer;
}

.container-btn {
  display: flex;
  justify-content: center;
  margin-right: 90px;
}
.new-btn {
  cursor: pointer;
}

@media (max-width: 600px) {
  .site-name {
    font-size: 47px;
  }
  .weare {
    margin-bottom: 50px;
  }
  .container {
    background-size: cover;
    min-height: 500px;
    padding: 0px;
    padding: 0px 20px;
  }
  .subtitle {
    width: 90%;
  }
  .container-btn {
    margin-right: 15px;
  }
  .whatwedo {
    padding-top: 50px;
  }
  .call-to-action {
    margin-top: 50px;
  }
}
</style>
