<template>
  <div class="blog-podcast-container">
    <img
      :src="podcast.header_image"
      class="podcast-image"
      alt="podcast image"
    />
    <p>
      <strong>{{ podcast.name }}</strong>
    </p>
    <br />
    <WeTheRootButton buttonSize="small">
      <a :href="podcast.spotify" target="_blank">Spotify</a>
    </WeTheRootButton>
    <WeTheRootButton buttonSize="small">
      <a :href="podcast.youtube" target="_blank">Youtube</a>
    </WeTheRootButton>
  </div>
</template>
<script>
import WeTheRootButton from "@/components/UiComponents/WeTheRootButton";

export default {
  name: "PodcastCard",
  components: { WeTheRootButton },
  props: {
    podcast: Object,
  },
  created() {
    console.log(this.podcast);
  },
  methods: {},
};
</script>

<style scoped>
.pax-pic {
  width: 220px;
}

.blog-podcast-container {
  width: 100%;
  text-align: center;
  margin: 70px 0;
  margin-bottom: 20px;
}
.podcast-image {
  width: 270px;
}

.external-link {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 14px;
  border-radius: 8px;
  border: 1.5px solid black;
  padding: 5px 10px;
  background-color: white;
  margin-right: 5px;
  cursor: pointer;
}

.external-link:hover {
  background-color: antiquewhite;
}
</style>
