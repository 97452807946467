<template>
  <div class="container">
    <a :href=resource.link target="_blank">
      <img

          class="pax-pic"
          :src=resource.image
          alt={{resource.image_alt}}
      />
      <h1>{{ resource.title }}</h1>
    </a>
  </div>
</template>

<script>
export default {
  name: "Resource",
  props: {
    resource: Object
  },
}
</script>

<style scoped>
.container {
  width: 270px;
  text-align: center;
  margin: 32px;
}

h1 {
  font-family: var(--secondary-font);
  font-size: 18px;
  width: 270px;
  margin: 0 auto;
}
.pax-pic {
  width: 270px;
}
</style>