<template>
  <BannerResourcesV2 />
  <ProductResources />
  <EachResource />
  <DownBannerResourcesV2 />
  <Subscription />
</template>
<script>
import BannerResourcesV2 from "../components/ResourcesComponent/BannerResourcesV2.vue";
import ProductResources from "../components/ResourcesComponent/ProductResources.vue";
import Subscription from "../components/ResourcesComponent/Subscription";
import EachResource from "@/components/ResourcesComponent/EachResource.vue";
import DownBannerResourcesV2 from "@/components/ResourcesComponent/DownBannerResourcesV2.vue";

export default {
  name: "Home",
  components: {
    BannerResourcesV2,
    ProductResources,
    EachResource,
    DownBannerResourcesV2,
    Subscription,
  },
};
</script>