<template>
  <BannerFilms />
  <SecondFilms />
  <FilmsServices />
  <FilmsWork />
</template>
<script>
import BannerFilms from "../components/FilmsComponent/BannerFilms.vue";
import SecondFilms from "../components/FilmsComponent/SecondFilms.vue";
import FilmsServices from "../components/FilmsComponent/FilmsServices.vue";
import FilmsWork from "../components/FilmsComponent/FilmsWork.vue";

export default {
  name: "Films",
  components: {
    BannerFilms,
    SecondFilms,
    FilmsServices,
    FilmsWork,
  }
};
</script>
