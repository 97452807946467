<template>
    <div class="container">
        <div class="purchase-container" v-if="this.successfulPurchase">
            <p><span class="product-info-title">{{
                $t("resources_detail_v2.thanks_purchase")
                }} </span></p>
            <br>
            <p>
              <span class="product-info-title" v-if="!!this.resourceFile">
              {{$t("resources_detail_v2.product_link") }} <a :href="this.resourceFile" target="_blank">link</a>
              </span>
              <span class="product-info-title" v-else>
              {{$t("resources_detail_v2.be_in_touch") }}
              </span>
            </p>
        </div>
        <div class="container-product"
             v-if="this.product && !this.successfulPurchase">
            <div class="product-pictures">
                <img class="product-picture" :src="product.images[0]"
                     alt="image"/>
            </div>
            <div class="product-info">
                <div class="product-info-text-container">
                    <p><span class="product-info-title">{{
                        this.product.name
                        }}</span></p>
                    <p><span class="product-info-price">{{
                        this.product.price
                        }}€</span></p>
                    <p><span>{{ this.product.description }}</span></p>

                    <div v-if="this.product.body">
                        <VueMarkdown
                                :source="this.product.body"
                                :options="{ html: true }"
                        ></VueMarkdown>
                    </div>

                    <WeTheRootGreenButton buttonSize="large" @click="submit">
                        <span class="white-link">{{
                            $t("resources_detail_v2.buy")
                            }}</span>
                    </WeTheRootGreenButton>
                </div>
            </div>
        </div>
        <div class="container-back-button">
            <router-link
                    :to="{
        name: 'ResourcesV2'
      }"
            >
                <strong><h1 class="text-center">
                    {{ $t("resources_detail_v2.go_back") }}</h1></strong>
            </router-link>
        </div>
    </div>
</template>
<script>

import {getProductResource, getResourceURL} from "@/resources";
import WeTheRootGreenButton
    from "@/components/UiComponents/WeTheRootGreenButton.vue";
import VueMarkdown from "vue-markdown-render";
import {getPaymentLink} from "@/stripe";

export default {
    name: "ProductResourceDetail",
    data() {
        return {
            product: null,
            loading: false,
            lineItems: [],
            successURL: window.location.href + "?success=true",
            cancelURL: window.location.href,
            paymentLink: "",
            resourceFile: ""
        };
    },
    computed: {
        successfulPurchase() {
            return this.$route.query.success === "true";
        },
    },
    components: {WeTheRootGreenButton, VueMarkdown},
    props: ["id", "slug", "productResource"],
    async beforeCreate() {
        this.product = await getProductResource(this.slug);
        this.lineItems.push({price: this.product.price_id, quantity: 1})
        try {
          this.resourceFile = await getResourceURL(this.product.file);
        } catch (e) {
          console.log("No resource file");
        }
        this.paymentLink = await getPaymentLink(this.product.price_id, window.location.href + "?success=true");
    },
    methods: {
        async submit() {
          if(!this.paymentLink) {
            const localPaymentLink = await getPaymentLink(this.product.price_id, window.location.href + "?success=true");
            window.open(localPaymentLink, '_blank')
          }
          else {
            window.open(this.paymentLink, '_blank')
          }
            // You will be redirected to Stripe's secure checkout page
            //this.$refs.checkoutRef.redirectToCheckout();
        },
    },
};

</script>

<style scoped>
.image-container {
    padding: 30px;
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center;
}

.image-box {
    width: 100%;
}

.header {
    width: 100%;
}

.container {
    text-align: left;
    font-size: 18px;
}

.container-back-button {
    text-align: center;
    padding: 30px 60px;
}

.container-product {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fffcf4;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
}

.product-pictures {
    max-width: 600px;
    display: flex;
    justify-content: space-around;
    flex-direction: row-reverse;
}

.product-picture {
    margin: 48px;
    min-width: 200px;
    max-width: 600px;
}

.product-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 320px;
    max-width: 600px;
}

.product-info-text-container {
    min-width: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 48px;
}

.product-info-text-container p {
    margin: 12px 12px 12px 0;
    display: flex;
}

.white-link {
    color: white;
    text-decoration: none;
}


.product-info-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #687c6c;
}

.product-info-price {
    font-size: 20px;
    font-weight: bold;
    color: black;
}

.purchase-container {
    margin: 0 auto;
    padding: 48px;
    background: #fffcf4;
    text-align: center;
    align-items: center;
    justify-content: center;
}


.title {
    font-size: 30px;
    text-align: center;
    margin-top: 20px;
}

.author {
    max-width: 75%;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
}

.margin {
    max-width: 75%;
    margin-top: -20px;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
}

.content {
    max-width: 75%;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
}

.content:deep(a) {
    text-decoration: underline;
}

.content:deep(a:hover) {
    color: var(--green);
    text-decoration: underline;
}

@media (max-width: 600px) {
    .container {
        padding: 30px;
    }

    .content {
        max-width: 90%;
        text-align: justify;
        margin-left: auto;
        margin-right: auto;
    }

    .author {
        max-width: 90%;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        text-align: justify;
    }

    .margin {
        max-width: 90%;
        margin-top: -20px;
        text-align: justify;
        margin-left: auto;
        margin-right: auto;
    }
}

.container-loader {
    padding: 50px;
}
</style>
