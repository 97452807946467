<template>
  <div class="container">
    <div id="mc_embed_signup">
      <link
        href="//cdn-images.mailchimp.com/embedcode/classic-071822.css"
        rel="stylesheet"
        type="text/css"
      />
      <!-- Principio del widget integrado de Calendly -->
      <div class="calendly-inline-widget" data-url="https://calendly.com/beatrizsegura/30-minute-meeting?hide_gdpr_banner=1" style="min-width:320px;height:1112px;"></div>
      <!-- Final del widget integrado de Calendly -->
      <p class="form-banner">{{ $t("contact.form-banner-text") }}</p>
      <form
        action="https://wetheroot.us7.list-manage.com/subscribe/post?u=ba035337e3763b633587c6dd5&amp;id=6e1b5e759c&amp;f_id=00dcc3e4f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        class="validate"
        target="_blank"
        novalidate
      >
        <div id="mc_embed_signup_scroll">
          <div class="mc-field-group">
            <label for="mce-EMAIL"
              >Email <span class="asterisk">*</span>
            </label>
            <input
              type="email"
              value=""
              name="EMAIL"
              class="required email"
              id="mce-EMAIL"
              required
            />
            <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
          </div>
          <div class="mc-field-group">
            <label for="mce-NOMBRE"
              >{{ $t("home.form-name") }} <span class="asterisk">*</span>
            </label>
            <input
              type="text"
              value=""
              name="NOMBRE"
              class="required"
              id="mce-NOMBRE"
              required
            />
            <span id="mce-NOMBRE-HELPERTEXT" class="helper_text"></span>
          </div>
          <div class="mc-field-group">
            <label for="mce-APELLIDOS"
              >{{ $t("home.form-lastname") }} <span class="asterisk">*</span>
            </label>
            <input
              type="text"
              value=""
              name="APELLIDOS"
              class="required"
              id="mce-APELLIDOS"
              required
            />
            <span id="mce-APELLIDOS-HELPERTEXT" class="helper_text"></span>
          </div>
          <div class="mc-field-group size1of2">
            <label for="mce-PHONE"
              >{{ $t("home.phone") }} <span class="asterisk">*</span></label
            >
            <input
              type="text"
              name="PHONE"
              class=""
              value=""
              id="mce-PHONE"
              required
            />
            <span id="mce-PHONE-HELPERTEXT" class="helper_text"></span>
          </div>
          <div class="mc-field-group">
            <label for="mce-INFO"
              >{{ $t("home.tell-us") }} <span class="asterisk">*</span></label
            >
            <input
              type="text"
              value=""
              name="INFO"
              class=""
              id="mce-INFO"
              required
            />
            <span id="mce-INFO-HELPERTEXT" class="helper_text"></span>
          </div>
          <div class="mc-field-group">
            <label for="mce-WEB">{{ $t("home.rrss") }} </label>
            <input type="url" value="" name="WEB" class="url" id="mce-WEB" />
            <span id="mce-WEB-HELPERTEXT" class="helper_text"></span>
          </div>
          <div class="mc-field-group input-group">
            <strong
              >{{ $t("home.reason-contact") }}
              <span class="asterisk">*</span></strong
            >
            <ul>
              <li>
                <input
                  type="radio"
                  value="Quiero contratar un servicio"
                  name="RAZON"
                  id="mce-RAZON-0"
                  required
                />
                <label for="mce-RAZON-0">{{ $t("home.radio1") }}</label>
              </li>
              <li>
                <input
                  type="radio"
                  value="Quiero ser parte de vuestro equipo"
                  name="RAZON"
                  id="mce-RAZON-1"
                />
                <label for="mce-RAZON-1">{{ $t("home.radio2") }}</label>
              </li>
              <li>
                <input
                  type="radio"
                  value="Quiero proponeros algo"
                  name="RAZON"
                  id="mce-RAZON-2"
                />
                <label for="mce-RAZON-2">{{ $t("home.radio3") }}</label>
              </li>
            </ul>
            <span id="mce-RAZON-HELPERTEXT" class="helper_text"></span>
          </div>
          <div hidden="true">
            <input type="hidden" name="tags" value="6749363" />
          </div>
          <div id="mce-responses" class="clear">
            <div
              class="response"
              id="mce-error-response"
              style="display: none"
            ></div>
            <div
              class="response"
              id="mce-success-response"
              style="display: none"
            ></div>
          </div>
          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px" aria-hidden="true">
            <input
              type="text"
              name="b_ba035337e3763b633587c6dd5_6e1b5e759c"
              tabindex="-1"
              value=""
            />
          </div>
          <div class="clear">
            <button
              target="_blank"
              type="submit"
              @click.capture="sendForm"
              value="Enviar >"
              name="subscribe"
              id="mc-embedded-subscribe"
              class="button"
            >
              {{ $t("home.button-send") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { addNewHoldedLead } from "../../holded";

export default {
  name: "FormContact",
  mounted () {
    const calendlyScript = document.createElement('script')
    calendlyScript.setAttribute('type', 'text/javascript')
    calendlyScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
    document.head.appendChild(calendlyScript)
  },
  data() {
    return {
      formSent: false,
    };
  }, 
  methods: {
    sendForm: async () => {
      let myForm = document.getElementById("mc-embedded-subscribe-form");
      let formData = new FormData(myForm);
      const data = {};
      // need to convert it before using not with XMLHttpRequest
      for (let [key, val] of formData.entries()) {
        Object.assign(data, { [key]: val });
      }
      const newLead = {
        title: "Web",
        name: data.NOMBRE,
        last_name: data.APELLIDOS,
        email: data.EMAIL ?? "",
        phone: data.PHONE ?? "",
        social_links: [data.WEB],
        more_info_form: data.INFO,
        status: "contact_established",
        member: "team/dGKVNr6ibzYVVJjrZuVf",
        created_on: new Date(),
      };

      if (data.RAZON) {
        if (data.RAZON.includes("contratar"))
          newLead.contact_reason = "hire_service";
        else if (data.RAZON.includes("equipo"))
          newLead.contact_reason = "join_team";
        else if (data.RAZON.includes("proponeros"))
          newLead.contact_reason = "make_proposal";
      }
      await addNewHoldedLead(newLead)
    },
  },
};
</script>
<style scoped>
form {
}
#mc_embed_signup .helper_text {
  padding: 0;
}
#mc_embed_signup {
  background: transparent;
  clear: left;
  font-family: var(--secondary-font);
  width: 80%;
  margin: 0 auto;
}
#mc_embed_signup form {
  padding: 0;
  color: var(--text);
}
#mc_embed_signup .asterisk {
  color: var(--text);
  font-size: 20px;
  top: 4px;
}
#mc_embed_signup .indicates-required {
  text-align: left;
  margin-bottom: 15px;
}
#mc_embed_signup .mc-field-group {
  padding-bottom: 15px;
}
label {
  color: var(--text);
}
#mc_embed_signup input {
  border: 1px solid var(--text);
  width: 100%;
}
#mc_embed_signup .button {
  margin-top: 20px;
  background-color: white;
  color: var(--text);
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  width: 30%;
  border-radius: 20px;
  border: 1px solid var(--text);
  font-family: var(--secondary-font);
}

#mc_embed_signup .button:hover {
  margin-top: 20px;
  background-color: white;
  color: var(--text);
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  width: 30%;
  border-radius: 20px;
  border: 1px solid var(--text);
}

.container {
  text-align: left;
  min-height: 600px;
  padding: 90px 50px 300px 50px;
  background-image: url(../../assets/Asset6.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.breadcrumb {
  font-size: 18px;
  font-family: var(--secondary-font);
  text-align: left;
  margin-top: 90px;
  padding-left: 50px;
  font-weight: 600;
}
h2 {
  font-family: var(--principal-font);
  font-weight: 400;
  font-size: 35px;
}

input {
  font-family: var(--secondary-font);
  border-radius: 8px;
  border: 1.5px solid black;
  padding: 5px 8px;
  background-color: white;
  cursor: pointer;
  margin-right: 15px;
}
@media (max-width: 600px) {
  .breadcrumb {
    padding-left: 22px;
  }
  #mc_embed_signup {
    width: 90%;
  }
  .container {
    background-size: 100%;
    background-position: bottom;
    padding: 0px !important;
  }
  #mc_embed_signup input {
    border: 1px solid var(--text);
    width: 100%;
  }
  #mc_embed_signup .button {
    width: 50%;
  }
}
.form-banner {
  text-align: center;
  color: var(--text);
  font-family: var(--secondary-font);
  font-weight: 300;
  width: 90%;
  margin: 30px auto;
  font-size: 2em;
}
@media (max-width: 600px) {
  .form-banner {
    width: 90%;
    margin-bottom: 70px;
  }
}
</style>
