import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import VueNavigationBar from "vue-navigation-bar";

// TODO: this import is not working in prod builds, but works in dev builds
// There is a workaround in the index.html file
import 'vue-navigation-bar/dist/vue-navigation-bar.css';

import {library} from "@fortawesome/fontawesome-svg-core";
import {
    faPhone,
    faFolderOpen,
    faBars,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import {i18n} from "./mixins/i18n"
import 'aos/dist/aos.css'
import AOS from 'aos'
import VueScrollTo from 'vue-scrollto'
import VueGtag from "vue-gtag"
import cookieconsent from 'vue-cookieconsent-component'


library.add(faPhone, faFolderOpen, faBars, faXmark);


createApp(App).use(router).use(i18n).use(AOS.init()).use(VueScrollTo).use(VueGtag, {config: {id: "G-BE2G25340G"}})
    .component('vue-navigation-bar', VueNavigationBar)
    .component("font-awesome-icon", FontAwesomeIcon).component('cookie-consent', cookieconsent).mount('#app')
