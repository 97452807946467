<template>
  <div class="container">
    <div class="first">
      <p class="breadcrumb">{{ $t("lab.breadcrumb") }}</p>
      <h1>{{ $t("lab.banner") }}</h1>
    </div>
    <div class="second">
      <div class="center-text">{{ $t("lab.text") }}</div>
      <br />
      <ul>
        <li>{{ $t("lab.text1") }}</li>
        <br />
        <li>{{ $t("lab.text2") }}</li>
      </ul>
    </div>
    <button class="btn">
      <a
        target="_blank"
        href="https://www.therootlab.org/"
        >{{ $t("lab.button") }}</a
      >
    </button>
  </div>
</template>
<script></script>
<style scoped>
.container {
  font-family: var(--secondary-font);
  min-height: 450px;
}
.breadcrumb {
  font-weight: 900;
  padding-top: 20px;
}
h1 {
  font-weight: 300;
  width: 70%;
  margin: 30px auto;
}
h2 {
  font-family: var(--secondary-font);
  font-weight: 400;
  font-size: 25px;
  letter-spacing: 3px;
}
h3 {
  font-family: var(--secondary-font);
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: 500;
}
p {
  font-weight: 400;
}
.second {
  padding: 30px 70px;
  text-align: left;
  font-weight: 400;
  font-family: var(--principal-font);
  font-size: 22px;
}
.btn {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 17px;
  border-radius: 8px;
  border: 1.5px solid var(--text);
  padding: 9px 15px;
  background-color: white;
  cursor: pointer;
  margin: 50px 0;
}

@media (max-width: 600px) {
  h1 {
    margin-bottom: 70px;
    width: 90%;
  }
  .second {
    padding: 0px 25px;
  }
  .center-text {
    text-align: center;
  }
}
</style>
