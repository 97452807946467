<template>
  <div class="container">
    <h2>
      {{ $t("blog.banner-newsletter") }} <br />
      {{ $t("blog.banner-newsletter1") }}
      {{ $t("blog.banner-newsletter2") }}
    </h2>
    <div class="form_mailchimp">
      <div id="mc_embed_signup">
        <form
          action="https://wetheroot.us7.list-manage.com/subscribe/post?u=ba035337e3763b633587c6dd5&amp;id=6e1b5e759c&amp;f_id=00dcc3e4f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          class="validate"
          target="_blank"
          novalidate
        >
          <div class="indicates-required">
            <span class="asterisk">*</span> indicates required
          </div>
          <div id="mc_embed_signup_scroll">
            <div class="mc-field-group">
              <label for="mce-NOMBRE">
                {{ $t("home.form-name") }} <span class="asterisk">*</span>
              </label>
              <input
                type="text"
                value=""
                name="NOMBRE"
                class="required"
                id="mce-NOMBRE"
                required
              />
              <span id="mce-NOMBRE-HELPERTEXT" class="helper_text"></span>
            </div>
            <div class="mc-field-group">
              <label for="mce-APELLIDOS">
                {{ $t("home.form-lastname") }}<span class="asterisk">*</span>
              </label>
              <input
                type="text"
                value=""
                name="APELLIDOS"
                class="required"
                id="mce-APELLIDOS"
                required
              />
              <span id="mce-APELLIDOS-HELPERTEXT" class="helper_text"></span>
            </div>
            <div class="mc-field-group">
              <label for="mce-EMAIL"
                >Email <span class="asterisk">*</span>
              </label>
              <input
                type="email"
                name="EMAIL"
                class="required email"
                id="mce-EMAIL"
                required
              />
              <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
            </div>
            <div hidden="true">
              <input type="hidden" name="tags" value="3525470,6752427" />
            </div>
            <div id="mce-responses" class="clear">
              <div
                class="response"
                id="mce-error-response"
                style="display: none"
              ></div>
              <div
                class="response"
                id="mce-success-response"
                style="display: none"
              ></div>
            </div>
            <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
            <div style="position: absolute; left: -5000px" aria-hidden="true">
              <input
                type="text"
                name="b_ba035337e3763b633587c6dd5_6e1b5e759c"
                tabindex="-1"
                value=""
              />
            </div>
            <div class="clear">
              <button
                target="_blank"
                type="submit"
                value="ENVIAR >"
                name="subscribe"
                id="mc-embedded-subscribe"
                class="button"
              >
                {{ $t("home.button-send") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script></script>
<style scoped>
.form_mailchimp {
  align-items: center;
  width: fit-content;
  margin: 0 auto;
}
#mc_embed_signup {
  background: transparent;
  clear: left;
  font-family: var(--secondary-font);
  width: 100%;
}
#mc_embed_signup form {
  padding: 0;
  color: var(--text);
}
#mc_embed_signup .asterisk {
  color: var(--text);
  font-size: 20px;
  top: 4px;
}
#mc_embed_signup_scroll {
  display: flex;
  flex-direction: row;
  align-items: center;
}

#mc_embed_signup .mc-field-group {
  padding-bottom: 15px;
}
label {
  color: var(--text);
}
#mc_embed_signup input {
  border: 1px solid var(--text);
  margin-right: 10px;
  width: 90%;
}
#mc_embed_signup .button {
  margin-top: 20px;
  background-color: white;
  color: var(--text);
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  border-radius: 20px;
  border: 1px solid var(--text);
  font-family: var(--secondary-font);
}

#mc_embed_signup .button:hover {
  margin-top: 20px;
  background-color: white;
  color: var(--text);
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  border-radius: 20px;
  border: 1px solid var(--text);
}
#mc_embed_signup div#mce-responses {
  display: none;
}
.container {
  text-align: center;
  min-height: 300px;
  padding: 55px 20px;
}
.breadcrumb {
  font-size: 18px;
  font-family: var(--secondary-font);
  text-align: left;
  margin-top: 90px;
  padding-left: 50px;
  font-weight: 600;
}
h2 {
  font-family: var(--principal-font);
  font-weight: 400;
  font-size: 35px;
  margin-left: 20px;
}

input {
  font-family: var(--secondary-font);
  border-radius: 8px;
  border: 1.5px solid black;
  padding: 5px 8px;
  background-color: white;
  cursor: pointer;
}
@media (max-width: 600px) {
  .breadcrumb {
    padding-left: 22px;
  }
  .container {
    margin: 0;
    padding: 20px;
    background-size: 100%;
    background-position: bottom;
  }
  #mc_embed_signup input {
    border: 1px solid var(--text);
    width: 110%;
  }
  #mc_embed_signup_scroll {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h2 {
    margin-left: 0;
  }

  #mc_embed_signup {
    margin-left: -20px;

  }
}
</style>
