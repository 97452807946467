<template>
  <div class="resources-view-container">
    <div class="resources-info">
      <p>{{$t("resources_v2.down_banner_content")}}</p>
      <br>
      <p>{{$t("resources_v2.down_banner_second_content")}}</p>
    </div>
  </div>
</template>

<script></script>
<style scoped>
.resources-view-container {
  padding: 0 0px;
  margin: 0 auto;
}

h1 {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 45px;
  letter-spacing: 3px;
}

h2 {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 3px;
}

h3 {
  font-family: var(--secondary-font);
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: 500;
}

p {
  font-weight: 400;
}

@media (max-width: 600px) {
  h1 {
    margin-bottom: 70px;
    width: 90%;
  }
}

.resources-info {
  max-width: 80%;
  font-family: var(--secondary-font);
  font-size: 16px;
  margin: 0 auto;
  margin-bottom: 60px;
  text-center: center;
}
</style>
