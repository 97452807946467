import axios from 'axios'
//const HoldedClient = require('holded-client');
//adding authentication API key => put this in a dotenv afterwards
const apiKey = 'd50531e80277faed93ba494767c7bcd8'
//funnel asked by the client
const funnelId = '630cdf81bc33e75c1f072ac3'


const createHoldedContact = async contactInfo => {
    try {
        const url = `/api_holded_contacts`;
        const options = {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                key: apiKey
            },
            body: JSON.stringify({
                socialNetworks: {website: contactInfo.website},
                name: contactInfo.name,
                email: contactInfo.email,
                phone: contactInfo.phone
            })
        };

        const response = await fetch(url, options);
        const data = await response.json();

        return data.id
        
    } catch (error) {
        console.error(error)
    }
}

const getHoldedContactIdIfExists = async contactInfo => {
    try {
        // gettin list of all contacts
        const options = {
            method: 'GET',
            url: '/api_holded_contacts',
            headers: {accept: 'application/json', key: apiKey}
        };
        
        const { data } = await axios.request(options)
        
        //trying to find if this user is already in the system
        const contact = data.find(
            contact => (
                contact.name === contactInfo.name &&
                contact.email === contactInfo.email &&
                contact.phone === contactInfo.phone
            )
        );
        if(contact) {
            return contact.id
        } else {
            return null
        }
    } catch (error) {
        return null
    }
}

const createLead = async (contactId, contactName, contactReason) => {
    try {
        const beautifyContactReason = {
            "hire_service": "I would like to work with you",
            "join_team": "I would like to be part of your team",
            "make_proposal": "I have a proposal"
        };
        
        const beautifulContactReason = beautifyContactReason[`${contactReason}`]
        const url = `/api_holded_leads`;
        const options = {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                key: apiKey
            },
            body: JSON.stringify({
                funnelId: funnelId,
                name: `${contactName}: ${beautifulContactReason}`, 
                contactId: contactId
            })
        };

        const response = await fetch(url, options);
        const data = await response.json();

        return data
        
    } catch (error) {
        console.error(error)
        return null
    }
}


export const addNewHoldedLead = async (rawFormInfo) => {
    try {
        let contactId;
        const formInfo = {
            name: `${rawFormInfo.name} ${rawFormInfo.last_name}`,
            email: rawFormInfo.email,
            phone: rawFormInfo.phone,
            website: rawFormInfo.social_links[0],
            contactReason: rawFormInfo.contact_reason
        }

        contactId = await getHoldedContactIdIfExists({
            name: formInfo.name,
            email: formInfo.email,
            phone: formInfo.phone
        })
        if(!contactId) {
            contactId = await createHoldedContact({
                socialNetworks: { website: formInfo.website },
                name: formInfo.name,
                email: formInfo.email,
                phone: formInfo.phone,
            })            
        }
        if(contactId) {
            const data = await createLead(contactId, formInfo.name, formInfo.contactReason)
            console.log(data)
        } else {
            console.log(contactId)
        }
    } catch (error) {
        console.log(error);
    }
}
