<template>
  <h2 class="breadcrumb">{{ $t("home.breadcrumb-fourth") }}</h2>
  <div class="container">
    <h2>
      {{ $t("home.newsletter-title") }} <br />
      {{ $t("home.newsletter-title1") }}
    </h2>
    <p>
      {{ $t("home.newsletter-text") }} <br />
      {{ $t("home.newsletter-text1") }} <br />
      {{ $t("home.newsletter-text2") }}
    </p>
    <link
      href="//cdn-images.mailchimp.com/embedcode/classic-071822.css"
      rel="stylesheet"
      type="text/css"
    />
    <div id="mc_embed_signup">
      <form
        action="https://wetheroot.us7.list-manage.com/subscribe/post?u=ba035337e3763b633587c6dd5&amp;id=6e1b5e759c&amp;f_id=00dcc3e4f0"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        class="validate"
        target="_blank"
        novalidate
      >
        <div id="mc_embed_signup_scroll">
          <div class="indicates-required">
            <span class="asterisk">*</span> indicates required
          </div>
          <div class="mc-field-group">
            <label for="mce-NOMBRE"
              >{{ $t("home.form-name") }} <span class="asterisk">*</span>
            </label>
            <input
              type="text"
              value=""
              name="NOMBRE"
              class="required"
              id="mce-NOMBRE"
              required
            />
            <span id="mce-NOMBRE-HELPERTEXT" class="helper_text"></span>
          </div>
          <div class="mc-field-group">
            <label for="mce-APELLIDOS"
              >{{ $t("home.form-lastname") }} <span class="asterisk">*</span>
            </label>
            <input
              type="text"
              value=""
              name="APELLIDOS"
              class="required"
              id="mce-APELLIDOS"
              required
            />
            <span id="mce-APELLIDOS-HELPERTEXT" class="helper_text"></span>
          </div>
          <div class="mc-field-group">
            <label for="mce-EMAIL"
              >Email <span class="asterisk">*</span>
            </label>
            <input
              type="email"
              value=""
              name="EMAIL"
              class="required email"
              id="mce-EMAIL"
              required
            />
            <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
          </div>
          <div hidden="true">
            <input type="hidden" name="tags" value="6752255,3525470" />
          </div>
          <div id="mce-responses" class="clear">
            <div
              class="response"
              id="mce-error-response"
              style="display: none"
            ></div>
            <div
              class="response"
              id="mce-success-response"
              style="display: none"
            ></div>
          </div>
          <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
          <div style="position: absolute; left: -5000px" aria-hidden="true">
            <input
              type="text"
              name="b_ba035337e3763b633587c6dd5_6e1b5e759c"
              tabindex="-1"
              value=""
            />
          </div>
          <div class="clear">
            <button
              target="_blank"
              type="submit"
              name="subscribe"
              id="mc-embedded-subscribe"
              class="button"
            >
              {{ $t("home.button-send") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script></script>
<style scoped>
#mc_embed_signup {
  background: transparent;
  margin-left: -20px;
  font-family: var(--secondary-font);
  width: 100%;
}
#mc_embed_signup_scroll {
}
#mc_embed_signup form {
  padding: 0;
  color: var(--text);
}
#mc_embed_signup .asterisk {
  color: var(--text);
  font-size: 20px;
  top: 4px;
}
#mc_embed_signup .indicates-required {
  text-align: left;
  margin-bottom: 15px;
}
#mc_embed_signup .mc-field-group {
  padding-bottom: 15px;
  width: 75%;
}
label {
  color: var(--text);
}
#mc_embed_signup input {
  border: 1px solid var(--text);
}
#mc_embed_signup .button {
  margin-top: 20px;
  background-color: white;
  color: var(--text);
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  width: 15%;
  border-radius: 20px;
  border: 1px solid var(--text);
  font-family: var(--secondary-font);
}

#mc_embed_signup .button:hover {
  margin-top: 20px;
  background-color: white;
  color: var(--text);
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  width: 15%;
  border-radius: 20px;
  border: 1px solid var(--text);
}
.container {
  text-align: left;
  min-height: 350px;
  padding: 20px 50px;
  background-image: url(../../assets/gradiente-newsletter.svg);
  background-repeat: no-repeat;
  background-size: 130%;
  background-position: center;
}

#mc_embed_signup .helper_text {
  padding: 0;
}
.breadcrumb {
  font-size: 18px;
  font-family: var(--secondary-font);
  text-align: left;
  margin-top: 90px;
  padding-left: 50px;
  font-weight: 600;
}
h2 {
  font-family: var(--principal-font);
  font-weight: 400;
  font-size: 35px;
}

input {
  font-family: var(--secondary-font);
  border-radius: 8px;
  border: 1.5px solid black;
  padding: 5px 8px;
  background-color: white;
  cursor: pointer;
  margin-right: 15px;
}
@media (max-width: 600px) {
  .breadcrumb {
    padding-left: 30px;
  }
  .container {
    margin: 0;
    padding: 20px 30px;
    background-size: cover;
    background-position: bottom;
  }
  #mc_embed_signup input {
    border: 1px solid var(--text);
    width: 115%;
  }
  #mc_embed_signup {
    width: 100%;
    margin: 0 -20px;
  }
  #mc_embed_signup .button {
    width: 45%;
  }
  #mc_embed_signup_scroll {
  }
  #mc_embed_signup .mc-field-group {
    width: 110%;
  }
}

@media (max-width: 825px) {
  #mc_embed_signup .button {
    width: 45%;
  }
}
</style>
