<template>
  <div class="container-text" :scroll="handleScroll">
    <h1 class="title">
      {{ $t("films.title") }} <u>{{ $t("films.title1") }}</u> <br />
      {{ $t("films.title2") }}
    </h1>
    <p class="text">
      {{ $t("films.call-to-1") }}
    </p>
    <br />
    <p class="text">
      {{ $t("films.call-to-2") }}
    </p>
  </div>
</template>
<script>
export default {
  name: "SecondFilms",
  data() {
    return {};
  },
};
</script>
<style scoped>
.container-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 90px 0px 150px;
}
.title {
  font-family: var(--secondary-font);
  font-weight: 400;
  font-size: 40px;
  text-align: left;
}
.text {
  width: 500px;
  margin: 0 auto;
  text-align: left;
}
.path.active {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
  animation: dash 10s ease alternate forwards;
  animation-delay: 1s;
  animation-iteration-count: 1;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@media (max-width: 600px) {
  .title {
    padding-left: 20px;
  }
  .text {
    width: 90%;
  }
  .container-text {
    margin: 10px;
  }
}
</style>
