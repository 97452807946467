<template>
  <div class="resources-view-container">
    <div class="resources-info" v-if="!this.product?.seo_description">
      <p>{{$t("resources_v2.down_banner_content")}}</p>
      <br>
      <p>{{$t("resources_v2.down_banner_second_content")}}</p>
    </div>
    <div class="resources-info" v-else>
      <p>{{this.product.seo_description}}</p>
    </div>
  </div>
</template>

<script>

import {getProductResource} from "@/resources";

export default {
  name: "ResourceDetailSeo",
  data() {
    return {
      product: null,
    };
  },
  props: ["slug"],
  async beforeCreate() {
    this.product = await getProductResource(this.slug);
  }
};

</script>
<style scoped>
.resources-view-container {
  max-width: 1300px;
  padding: 0 16px;
  margin: 0 auto;
  margin-top: 20px;
}

h1 {
  font-family: var(--secondary-font);
  margin-bottom: 0;
  font-size: 18px;
}

.resources-info {
  max-width: 80%;
  font-family: var(--secondary-font);
  font-size: 16px;
  margin: 0 auto;
  margin-bottom: 60px;
  text-center: center;
}

</style>