<template>
  <div class="container">
    <div class="first">
      <p class="breadcrumb">{{ $t("contact.breadcrumb-contacto") }}</p>
      <h1>{{ $t("contact.text-contact") }}</h1>
      <h1>{{ $t("contact.text-contact2") }}</h1>
    </div>
  </div>
</template>
<script></script>
<style scoped>
.container {
  font-family: var(--secondary-font);
}
.breadcrumb {
  font-weight: 900;
  padding-top: 20px;
}
h1 {
  font-weight: 300;
  width: 70%;
  margin: 30px auto;
}
h2 {
  font-family: var(--secondary-font);
  font-weight: 400;
  font-size: 25px;
  letter-spacing: 3px;
}
h3 {
  font-family: var(--secondary-font);
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: 500;
}
p {
  font-weight: 400;
}
@media (max-width: 600px) {
  h1 {
    width: 90%;
    margin-bottom: 70px;
  }
}
</style>
