import { createI18n } from "vue-i18n";
// loadLocaleMessages gets the JSON files from the project that includes the
// traslations of the located messages.
const loadLocaleMessages = () => {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });

    return messages;
}

export const i18n = createI18n({
    locale: navigator.language,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages: loadLocaleMessages()
})

// Exports an initialized instance of VueI18n plugin to work with locales
// translations.

