import axios from "axios";

export const getPaymentLink = async (priceId, returnUrl) => {
  try {
    const {data} = await axios.post("https://europe-west3-we-the-root-blog.cloudfunctions.net/createStripeCheckoutLink", {
      price_id: priceId,
      return_url: returnUrl
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    return data.purchase_url;
  } catch (e) {
    console.error(e);
    return null;
  }
}