import { db, storage } from "./firebase";
import {collection, doc, getDoc, getDocs, query, orderBy, where} from "firebase/firestore";
import { getDownloadURL, ref} from "firebase/storage";

// methods

const getImageUrl = async (image) => {
  const imageRef = ref(storage, image)
  const imageUrl = await getDownloadURL(imageRef);
  return imageUrl;
}


const getAuthor = async (articleDoc) => {
  let authorRef = articleDoc.author;
  if (authorRef) {
    const authorDoc = await getDoc(authorRef);
    const author = authorDoc.data();
    if (author.image) {
      author.image = await getImageUrl(author.image);
    }
    return author;
  }
}

const getOneArticle = async (doc) => {
  let article = doc.data();
  article.id = doc.id
  article.author = await getAuthor(article);
  if (article.header_image) {
    article.header_image = await getImageUrl(article.header_image);
  }
  return article;
}
export const getLastArticles = async () => {
  const articlesRef = collection(db, "articles");
  const querySnapshot = await getDocs(query(articlesRef, where("status", "==", "published"), orderBy("created_on", "desc")));
  const articles = await Promise.all(querySnapshot.docs.map(getOneArticle));
  return articles;
}

export const getArticle = async (articleId) => {
  try {
    const someDoc = await getDoc(doc(db, "articles", articleId));
    let article = someDoc.data();
    article.id = someDoc.id
    article.author = await getAuthor(article);
    if (article.header_image) {
      article.header_image = await getImageUrl(article.header_image);
    }
    for(const content of article.content){
      if(content.type === "images"){
        for(let i = 0; i < content.value.length ; i++){
          content.value[i] = await getImageUrl(content.value[i]);
        }
      }
    }
    return article;
  } catch (e) {
    return;
  }

}

