<template>
  <h2 class="breadcrumb">{{ $t("films.breadcrumb1") }}</h2>
  <div class="container">
    <div class="info-services">
      <h2>{{ $t("films.our-work") }}</h2>
      <p>
        {{ $t("films.work-desc") }}
      </p>
    </div>

    <hr />

    <div class="info-services">
      <h2>{{ $t("films.our-work1") }}</h2>
      <p>
        {{ $t("films.work-desc1") }}
      </p>
    </div>

    <hr />

    <div class="info-services">
      <h2>{{ $t("films.our-work2") }}</h2>
      <p>
        {{ $t("films.work-desc2") }}
      </p>
    </div>
    <hr />

    <div class="info-services">
      <h2>{{ $t("films.our-work3") }}</h2>
      <p>
        {{ $t("films.work-desc3") }}
      </p>
    </div>

    <hr />
  </div>
</template>
<script>
export default {
  name: "SecondBanner",
};
</script>
<style scoped>
.container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  background-image: url(../../assets/servicios/gradient-films.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  min-height: 600px;
}
.breadcrumb {
  font-size: 17px;
  font-family: var(--secondary-font);
  text-align: left;
  margin-top: 20px;
  padding-left: 50px;
  font-weight: 600;
}
h2 {
  font-family: var(--secondary-font);
  font-weight: 400;
  font-size: 35px;
  margin-bottom: -10px;
}
.symb {
  font-size: 45px;
  font-weight: 500;
}
.info-services {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  margin: 0 auto;
  min-height: 160px;
}
.strategic,
.branding,
.films {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.call-to-action {
  margin-top: 90px;
}
h2 {
  width: 50%;
  margin: 0;
}
p {
  width: 50%;
  margin: 0;
}
hr {
  width: 93%;
}

.title {
  font-size: 35px;
  font-weight: 300;
}
.btn {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 17px;
  border-radius: 8px;
  border: 1.5px solid black;
  padding: 9px 15px;
  background-color: white;
  margin-top: 10px;
  cursor: pointer;
}

/* marta bermejo */
.testimony {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 90px;
}
.comillas {
  font-size: 90px;
  margin-bottom: -30px;
}

.container-client {
  padding-top: 50px;
  padding-left: 30px;
  width: 50%;
}
.client-name {
  font-size: 20px;
  margin: 0;
  font-weight: 500;
}
.client-text {
  font-size: 18px;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
}
img {
  width: 20%;
  height: 20%;
  border-radius: 50%;
  padding-top: 50px;
}
@media (max-width: 600px) {
  .strategic,
  .branding,
  .films {
    justify-content: space-between;
  }
  .info-services {
    width: 95%;
    margin: 10px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .testimony {
    flex-direction: column;
  }
  .container-client {
    width: 90%;
    padding-left: 0;
  }

  hr {
    background-color: var(--text);
    width: 90%;
  }
  h2 {
    width: 90%;
    margin: 0;
  }
  p {
    width: 90%;
    margin: 0;
  }
  img {
    width: 40%;
  }
  .breadcrumb {
    padding-left: 30px;
  }
}
</style>
