import {db, storage} from "./firebase";
// eslint-disable-next-line no-unused-vars
import {collection, getDocs, query, orderBy, where, getDoc} from "firebase/firestore";
import {getDownloadURL, ref} from "firebase/storage";

const getImageUrl = async (image) => {
  const imageRef = ref(storage, image)
  const imageUrl = await getDownloadURL(imageRef);
  return imageUrl;
}

export const getResourceURL = async (resource) => {
  const resourceRef = ref(storage, resource)
  const resourceUrl = await getDownloadURL(resourceRef);
  return resourceUrl;
}

const getOneResource = async (doc) => {
  let resource = doc.data();
  resource.id = doc.id
  if (resource.image) {
    resource.image = await getImageUrl(resource.image);
  }
  return resource;
}

export const getResources = async () => {
  const podcastsRef = collection(db, "resources");
  const querySnapshot = await getDocs(query(podcastsRef, orderBy("created_on", "desc")));
  const resources = await Promise.all(querySnapshot.docs.map(getOneResource));
  return resources;
}

const getPrices = async (doc) => {
  const productData = doc.data();
  console.log(productData);
  if(productData.custom_image)
    productData.images = [await getImageUrl(productData.custom_image)];
  const prices =  await getDocs(collection(db, "products", doc.id, "prices"));
  let pricesData = prices.docs.map(price => {
    return {id:price.id, ...price.data()}
  })
  pricesData = pricesData.filter(price => price.active);
  if(pricesData.length > 0){
    productData.price = `${pricesData[0].unit_amount/100}`;
    productData.price_id = pricesData[0].id;
  }
  else productData.price = "Gratis";
  productData.id = doc.id;
  return productData;
}

export const getProductResources = async () => {
  const productRefs = collection(db, "products");
  const querySnapshot = await getDocs(query(productRefs, where("active", "==", true), where("show_in_website", "==", true), orderBy("created_on", "desc")));
  const products = await Promise.all(querySnapshot.docs.map(getPrices));
  return products;
}


export const getProductResource = async (slug) => {
  const productRefs = collection(db, "products");
  const querySnapshot = await getDocs(query(productRefs, where("slug", "==", slug)));
  const products = await Promise.all(querySnapshot.docs.map(getPrices));
  if(products && products.length > 0) return products[0];
}
