<template>
  <BannerGreenhouse />
  <BlogSubscription />
</template>
<script>
import BannerGreenhouse from "../components/GreenhouseComponent/BannerGreenhouse.vue";
import BlogSubscription from "../components/GreenhouseComponent/BlogSubscription.vue";

export default {
  name: "Films",
  components: {
    BannerGreenhouse,
    BlogSubscription,
  },
};
</script>
