<template>
  <BannerWe />
  <CallToAction />
  <Team />
</template>
<script>
import BannerWe from "../components/WeComponent/BannerWe.vue";
import CallToAction from "../components/WeComponent/CallToAction.vue";
import Team from "../components/WeComponent/Team.vue";

export default {
  name: "Strategic",
  components: {
    BannerWe,
    CallToAction,
    Team,
  },
};
</script>
