<template>
  <div class="container">
    <h1 class="breadcrumb">{{ $t("home.breadcrumb-second") }}</h1>
    <div class="container-how">
      <div class="consultoria">
        <h2>{{ $t("home.offer-first") }}</h2>
        <p>
          {{ $t("home.offer-first1") }}
        </p>
      </div>
      <hr />
      <div class="entregables">
        <h2>{{ $t("home.offer-second") }}</h2>
        <p>
          {{ $t("home.offer-second1") }}
        </p>
      </div>
      <hr />
      <div class="formacion">
        <h2>
          {{ $t("home.offer-third") }}
        </h2>
        <p>
          {{ $t("home.offer-third1") }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WhatWeDo",
  data() {
    return {};
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      var reveals = document.querySelectorAll(
        ".consultoria,.formacion,.entregables"
      );
      for (var i = 0; i < reveals.length; i++) {
        var windowHeight = window.innerHeight;
        var elementTop = reveals[i].getBoundingClientRect().top;
        var elementVisible = 150;
        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    },
  },
};
</script>
<style scoped>
.breadcrumb {
  font-size: 17px;
  font-family: var(--secondary-font);
  text-align: left;
  margin-top: 80px;
  margin-bottom: 60px;
  padding-left: 50px;
  font-weight: 600;
}

hr {
  border: none;
  border-left: 1px solid hsla(200, 10%, 50%, 100);
  height: 250px;
  width: 1px;
}
.container-how {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 0px 50px;
}
h2 {
  font-family: var(--secondary-font);
  font-weight: 400;
  font-size: 30px;
}

.consultoria.active {
  animation: fadeIn 1s;
}
.entregables.active {
  animation: fadeIn 4s;
}
.formacion.active {
  animation: fadeIn 7s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.consultoria,
.entregables,
.formacion {
  width: 23.3%;
}
@media (max-width: 600px) {
  .container-how {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 30px;
  }
  .breadcrumb {
    padding-left: 30px;
    margin: 60px 0 30px;
  }
  .consultoria,
  .entregables,
  .formacion {
    width: 100%;
  }
  hr {
    display: none;
  }
  h2 {
    margin-top: 30px;
    margin-bottom: 5px;
  }
}
</style>
