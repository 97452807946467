<template>
  <BannerBranding />
  <BrandingServices />
  <BrandingWork />
  <BrandingThird />
</template>
<script>
import BannerBranding from "../components/BrandingComponent/BannerBranding.vue";
import BrandingServices from "../components/BrandingComponent/BrandingServices.vue";
import BrandingThird from "../components/BrandingComponent/BrandingThird.vue";
import BrandingWork from "../components/BrandingComponent/BrandingWork.vue";

export default {
  name: "Home",
  components: {
    BannerBranding,
    BrandingServices,
    BrandingThird,
    BrandingWork,
  },
};
</script>
