<template>
  <div class="container">
    <div class="first">
      <p class="breadcrumb">{{ $t("we.breadcrumb") }}</p>
      <h1>
        {{ $t("home.banner") }} <br />
        <span id="spin"></span>
      </h1>
      <div class="text">
        <div>{{ $t("we.banner-sub1") }}</div>
        <br />
        <div>{{ $t("we.banner-sub2") }}</div>
      </div>
    </div>
  </div>
  <h2>{{ $t("we.copy-banner") }}</h2>
  <div class="services">
    <h3>{{ $t("we.title-we") }}</h3>
    <p>{{ $t("we.text-we") }}</p>
    <h3>{{ $t("we.title-we1") }}</h3>
    <p>{{ $t("we.text-we1") }}</p>
    <h3>{{ $t("we.title-we2") }}</h3>
    <p>{{ $t("we.text-we2") }}</p>
    <h3>{{ $t("we.title-we3") }}</h3>
    <p>{{ $t("we.text-we3") }}</p>
    <h3>{{ $t("we.title-we4") }}</h3>
    <p>{{ $t("we.text-we4") }}</p>
  </div>
</template>
<script></script>
<style scoped>
#spin:after {
  content: "";
  animation: spin 12s linear infinite;
}
@keyframes spin {
  0% {
    content: "consultoras";
  }

  16.6% {
    content: "creativas";
  }

  33.3% {
    content: "diseñadoras";
  }

  49.9% {
    content: "facilitadoras";
  }

  66.5% {
    content: "redactoras";
  }

  83.1% {
    content: "videógrafas";
  }

  100% {
    content: "org comunitarias";
  }
}
.container {
  display: flex;
  justify-content: center;
  font-family: var(--secondary-font);
  background-image: url(../../assets/Asset1.svg);
  background-repeat: no-repeat;
  min-height: 450px;
  background-position: center;
  background-size: 100%;
  background-position-y: top;
}
.breadcrumb {
  font-weight: 900;
  padding-top: 20px;
}
h1 {
  font-weight: 300;
  width: 90%;
  margin: 30px auto;
  font-size: 45px;
}
h2 {
  font-family: var(--secondary-font);
  font-weight: 400;
  font-size: 25px;

  margin-top: 40px;
}
h3 {
  font-family: var(--secondary-font);
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: 500;
}
p {
  font-weight: 400;
}
.services {
  padding-top: 20px;
  width: 38%;
  text-align: left;
  margin: 0 auto;
  padding-bottom: 50px;
}
.second {
}
.text {
  width: 60%;
  margin: 80px auto;
  text-align: center;
  font-weight: 400;
  font-family: var(--principal-font);
  font-size: 22px;
}
.logo {
  width: 255px;
}
@media (max-width: 600px) {
  h1 {
    margin-bottom: 70px;
    width: 90%;
    font-size: 32px;
  }
  .second {
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 90px;
  }
  .text {
    width: 70%;
    text-align: center;
  }
  .services {
    width: 85%;
  }
  h2 {
    margin: 0 21px;
  }
}
</style>
