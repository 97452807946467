<template>
  <BannerContact />
  <FormContact />
</template>
<script>
import BannerContact from "../components/ContactComponent/BannerContact.vue";
import FormContact from "../components/ContactComponent/FormContact.vue";

export default {
  name: "Films",
  components: {
    BannerContact,
    FormContact,
  }
};
</script>
