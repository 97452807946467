<template>
  <div class="container">
    <router-link to="estrategia-de-negocio" @click="scrollToTop">
      <div class="strategic">
        <img
          class="illustration"
          src="../../assets/branding/estrategia-de-negocio.png"
          alt="estrategia de negocio ilustracion"
        />
        <div class="info-services">
          <h2>
            {{ $t("home.list-services-first") }} <br />
            {{ $t("home.list-services-first1") }}
          </h2>
          <p class="margin-strategic">
            {{ $t("home.list-services-first3") }}
          </p>
        </div>
      </div>
    </router-link>
    <hr />
    <router-link to="branding" @click="scrollToTop">
      <div class="branding">
        <img
          class="illustration correct-margin"
          src="../../assets/branding/branding.png"
          alt=""
        />

        <div class="info-services">
          <h2>{{ $t("home.list-services-second") }}</h2>

          <p>{{ $t("home.list-services-second1") }}</p>
        </div>
      </div>
    </router-link>
    <hr />
    <router-link to="wetheroot-films" @click="scrollToTop">
      <div class="films">
        <img
          class="illustration"
          src="../../assets/branding/films.png"
          alt=""
        />
        <div class="info-services">
          <p class="wtrfilms">WE THE ROOT</p>

          <h2>{{ $t("home.list-services-third") }}</h2>

          <p>
            {{ $t("home.list-services-third1") }}
          </p>
        </div>
      </div>
    </router-link>
    <hr />
  </div>
</template>
<script>
export default {
  name: "SecondBanner",
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
<style scoped>
.illustration {
  margin: 0 10px;
  width: 15%;
}
.correct-margin {
  margin-top: 30px;
}
.container {
  display: flex;
  flex-direction: column;
}
h2 {
  font-family: var(--secondary-font);
  font-weight: 400;
  font-size: 35px;
  margin-bottom: 2px;
}
.info-services {
  text-align: left;
  width: 30%;
  margin-left: 70px;
}
.margin-strategic {
  margin-bottom: 30px;
}
.strategic,
.branding,
.films {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}
.branding {
  margin-top: -35px;
}
.wtrfilms {
  font-family: var(--secondary-font);
  margin-bottom: -30px;
  font-size: 14px;
}
hr {
  width: 100%;
}
@media (max-width: 600px) {
  .strategic,
  .branding,
  .films {
    justify-content: center;
  }
  .info-services {
    width: 60%;
    margin-left: 10px;
  }
  .illustration {
    width: 25%;
  }
  hr {
    background-color: var(--text);
    width: 95%;
  }
  h2 {
    font-size: 32px;
  }
}
</style>
