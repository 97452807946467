<template>
  <div class="resources-view-container">
    <div class="container">
      <div class="titles">
        <h1 class="title-spacer">{{ $t("resources_v2.banner_title") }}</h1>
        <h2>{{ $t("resources_v2.banner_description") }}</h2>
      </div>
    </div>
  </div>
</template>

<script></script>
<style scoped>
.resources-view-container {
  padding: 0 0px;
  margin: 0 auto;
}

.container {
  font-family: var(--secondary-font);
  background-image: url("../../assets/recursos/resources_hero_image.jpg");
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center bottom;
}

h1 {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 45px;
  letter-spacing: 3px;
}

h2 {
  font-family: var(--secondary-font);
  font-weight: 500;
  font-size: 25px;
  letter-spacing: 3px;
}

h3 {
  font-family: var(--secondary-font);
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: 500;
}

p {
  font-weight: 400;
}

@media (max-width: 600px) {
  h1 {
    margin: 0 auto;
    width: 90%;
  }

  h2 {
    margin: 0 auto;
    font-family: var(--secondary-font);
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 3px;
  }
}

.titles {
  margin-left: 28px;
  margin-right: 28px;
  padding-top: 80px;
  padding-bottom: 32px;
  text-align: center;
  color: white;
}

.title-spacer {
  margin-bottom: 70px;
}
</style>
