<template>
  <div v-if="status === 'loading'"></div>
  <div class="container" v-else-if="status === 'loaded'">
    <p class="title">
      <strong>{{ podcast[0].name }}</strong>
    </p>
    <button><a href="{{podcast.spotify}}"> Spotify</a></button>
    <button><a href="{{podcast[0].youtube}}">Youtube</a></button>
    <a href="{{podcast[0].youtube}}">{{ podcast[0].youtube }}</a>
    <br />
    <!-- Aquí en el segundo blog, te falta el author, así que tienes que contemplar esta opción -->
  </div>
  <div v-else>podcast not found</div>
</template>
<script>
import { getPodcasts } from "../../podcasts.js";

export default {
  name: "BannerGreenhouse",
  data() {
    return {
      podcast: {},
      status: "loading",
      id: "",
    };
  },
  methods: {},
  async mounted() {
    this.id = this.$route.params.id;
    console.log(this.id);
    this.podcast = await getPodcasts(this.id);
    console.log(this.podcast);
    if (this.podcast) {
      this.status = "loaded";
    } else this.status = "error";
    console.log(this.podcast);
  },
};
</script>
<style scoped>
.header {
  width: 100%;
}
.container {
  padding: 30px 60px;
  text-align: left;
  font-size: 18px;
}
.title {
  font-size: 30px;
  text-align: center;
  margin-top: 20px;
}
.author {
  margin-bottom: 20px;
}
.margin {
  margin-top: -20px;
}
@media (max-width: 600px) {
  .container {
    padding: 30px;
  }
}
</style>
