<template>
  <div class="resources-view-container">
    <div class="resources_container" v-if="this.resources.length > 0">
      <Resource
          v-for="resource in resources"
          :key="resource.id"
          :resource="resource"/>
    </div>

    <div class="container-loader" v-if="this.resources.length === 0">
      <InifiniteProgressLoader/>
    </div>
  </div>
</template>

<style scoped>
.resources-view-container {
  max-width: 1300px;
  padding: 0 16px;
  margin: 0 auto;
}

.container-loader {
  padding: 50px;
}

.resources_container {
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.resource-container {
  width: 20%;
}

.container-founders,
.container-workers,
.container-collabs {
  width: 20%;
  margin-top: 30px;
}

.pax-pic {
  width: 270px;
}

.founder,
.worker,
.collab {
  width: 20%;
  text-align: center;
}

.margin {
  margin-bottom: 100px;
}

h1 {
  font-family: var(--secondary-font);
  margin-bottom: 0;
  font-size: 18px;
}

.role {
  font-family: var(--secondary-font);
  margin-bottom: 20px;
}

</style>
<script>
import Resource from "@/components/ResourcesComponent/Resource";
import {getResources} from "@/resources";
import InifiniteProgressLoader from "@/components/GreenhouseComponent/InifiniteProgressLoader";

export default {
  components: {Resource, InifiniteProgressLoader},
  data() {
    return {
      resources: [],
    };
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
        () => this.$route.params,
        () => {
          this.fetchData()
        },
        // fetch the data when the view is created and the data is
        // already being observed
        {immediate: true}
    )
  },
  methods: {
    fetchData: async function () {
      try {
        this.resources = await getResources();
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>