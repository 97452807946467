<template>
    <NavBar/>
    <cookie-consent class="cookie-consent"></cookie-consent>
    <router-view/>
    <Footer/>

</template>

<script>
import NavBar from "./components/NavBar.vue";
import Footer from "./components/Footer.vue";

export default {
    name: "App",
    components: {
        NavBar,
        Footer,
    }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;400;600&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap");

button.cookie-consent-compliance {
    font-family: var(--secondary-font);
    font-weight: 500;
    font-size: 14px;
    border-radius: 8px;
    border: 1.5px solid black;
    padding: 5px 15px;
    background-color: white;
    cursor: pointer;
    margin: 20px 0 5px 0;
}

:root {
    --text: #434241;
    --lavender: #d7d7ff;
    --green: #b3c7a4;
    --caqui: #ffd86d;
    --principal-font: "Work Sans", sans-serif;
    --secondary-font: "Roboto Mono", monospace;
    --font-size-regular: 16px;
}

.cookie-consent {
    position: fixed;
    z-index: 1;
    background-color: white;
    border: 1px solid var(--lavender);
    padding: 10px 0px;
    bottom: 0%;
    box-shadow: 20px 10px 20px 10px rgba(0, 0, 0, 0.436) !important;
    border-radius: 10px;
    margin: 0 auto;
    width: 100%;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(224, 213, 224, 1);
}

#app {
    font-family: var(--principal-font);
    /* TODO: this is affectting the whole website, it should only be applied to individual elements */
    text-align: center;
    overflow-x: hidden;
    color: var(--text);
}

body {
    overflow-x: hidden;
    margin: 2px;
}

p {
    margin: 35px 0;
}

a {
  color: var(--text);
  text-decoration: none;
}

button {
    color: #000 !important;
}
</style>
