<template>
  <HeroBanner />
  <SecondBanner />
  <WhatWeDo />
  <Clients />
  <Carousel />
  <Newsletter />
</template>
<script>
import HeroBanner from "../components/HomeComponent/HeroBanner.vue";
import SecondBanner from "../components/HomeComponent/SecondBanner.vue";
import WhatWeDo from "../components/HomeComponent/WhatWeDo.vue";
import Clients from "../components/HomeComponent/Clients.vue";
import Carousel from "../components/HomeComponent/Carousel.vue";
import Newsletter from "../components/HomeComponent/Newsletter.vue";

export default {
  name: "Home",
  components: {
    HeroBanner,
    SecondBanner,
    WhatWeDo,
    Clients,
    Carousel,
    Newsletter,
  },
};
</script>
