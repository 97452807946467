// Your web app's Firebase configuration
import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAkBdXlT_cAeaM7ZoIRJ-TpgsyhlwYGyIM",
  authDomain: "we-the-root-blog.firebaseapp.com",
  projectId: "we-the-root-blog",
  storageBucket: "we-the-root-blog.appspot.com",
  messagingSenderId: "315200183168",
  appId: "1:315200183168:web:f8f220366624df483dbf65"
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
export const storage = getStorage();