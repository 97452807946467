<template>
  <h2 class="breadcrumb">{{ $t("strategic.breadcrumb-second") }}</h2>
  <div class="container parent">
    <div class="div1">
      <img src="../../assets/servicios/1.Branding5.png" alt="" />
      <h2 class="title">ALPACANA</h2>
      <p class="info">{{ $t("branding.clients-first") }}</p>
    </div>
    <div class="div1">
      <img src="../../assets/servicios/fotgrafia.png" alt="" />
      <h2 class="title">SIERRA ECO FOODS</h2>
      <p class="info">{{ $t("branding.clients-third") }}</p>
    </div>
  </div>
</template>
<style scoped>
.breadcrumb {
  font-size: 17px;
  font-family: var(--secondary-font);
  text-align: left;
  margin-top: 70px;
  margin-bottom: 60px;
  padding-left: 50px;
  font-weight: 600;
}
.parent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 25px;
  align-items: center;
  padding: 0 50px;
}

.div1 {
  text-align: left;
}
img {
  width: 100%;
}
.title,
.info {
  margin: 0;
}
.info {
  font-size: 14px;
}
@media (max-width: 600px) {
  .parent {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 25px;
    align-items: center;
    padding: 0 50px;
  }
  .breadcrumb {
    margin-bottom: 40px;
  }
  .title {
    font-size: var(--font-size-regular);
  }
}
</style>
