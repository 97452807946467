<template>
  <div class="container">
    <div class="site-name">{{ $t("blog.title") }}</div>
    <div class="container-services">
      <h2 class="subtitle">
        {{ $t("blog.subtitle") }}
      </h2>
    </div>
    <div class="container-btn">
      <p class="clickable" v-scroll-to="'#anchor'">
        / {{ $t("blog.go-to-blog") }}
      </p>
      <br />
      <p class="clickable" v-scroll-to="'#anchor1'">
        / {{ $t("blog.go-to-podcast") }}
      </p>
    </div>
  </div>
  <div class="blog-section">
    <div class="blog" id="anchor">BLOG</div>
    <h2 class="subtitle">
      {{ $t("blog.desc") }}
    </h2>
    <div v-if="this.articles.length > 0">
      <p class="breadcrumb">{{ $t("blog.breadcrumb") }}</p>
      <div class="container-workers">
        <BlogCard
          v-for="article in articles"
          :key="article.id"
          :article="article"
        ></BlogCard>
      </div>
      <WeTheRootButton
        buttonSize="large"
        @click="showMoreArticles"
        v-if="allArticles.length != articles.length"
      >
        {{ $t("blog.btn-more") }}
      </WeTheRootButton>
    </div>
    <div class="container-loader" v-if="this.articles.length === 0">
      <InifiniteProgressLoader />
    </div>
  </div>
  <div class="podcast-section">
    <div class="blog" id="anchor1">PODCAST</div>
    <h2 class="subtitle">
      {{ $t("podcast.desc1") }}
    </h2>
    <h2 class="subtitle">
      {{ $t("podcast.desc2") }}
    </h2>
    <div v-if="podcasts.length > 0">
      <p class="breadcrumb">{{ $t("podcast.breadcrumb") }}</p>
      <div class="container-workers">
        <PodcastCard
          v-for="podcast in podcasts"
          :key="podcast.id"
          :podcast="podcast"
        ></PodcastCard>
      </div>
    </div>
    <div class="container-loader" v-else>
      <InifiniteProgressLoader />
    </div>
  </div>
</template>
<script>
import { getLastArticles } from "../../blog.js";
import { getPodcasts } from "../../podcasts.js";
import BlogCard from "./BlogCard";
import PodcastCard from "./PodcastCard.vue";
import InifiniteProgressLoader from "@/components/GreenhouseComponent/InifiniteProgressLoader";
import WeTheRootButton from "@/components/UiComponents/WeTheRootButton";
export default {
  name: "BannerGreenhouse",
  components: {
    WeTheRootButton,
    InifiniteProgressLoader,
    BlogCard,
    PodcastCard,
  },
  data() {
    return {
      articles: [],
      allArticles: [],
      podcasts: [],
      allPodcasts: [],
    };
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
        () => this.$route.params,
        () => {
          this.fetchData()
        },
        // fetch the data when the view is created and the data is
        // already being observed
        { immediate: true }
    )
  },
  methods: {
    fetchData: async function()  {
      try {
        this.allArticles = await getLastArticles();
        this.articles = this.allArticles.slice(0, 4);
      } catch (error) {
        console.log(error);
      }
      try {
        this.allPodcasts = await getPodcasts();
        this.podcasts = this.allPodcasts.slice(0, 4);
      } catch (error) {
        console.error(error);
      }
    },
    showMoreArticles: function () {
      const pendingArticles = this.allArticles.slice(
        this.articles.length,
        this.articles.length + 4
      );
      this.articles = this.articles.concat(pendingArticles);
    },
    showMorePodcasts: function () {
      const pendingPodcasts = this.allPodcasts.slice(
        this.podcasts.length,
        this.podcasts.length + 4
      );
      this.podcasts = this.podcasts.concat(pendingPodcasts);
    },
  },
};
</script>
<style scoped>
.breadcrumb {
  font-weight: 900;
  padding-top: 50px;
  font-family: var(--secondary-font);
  text-align: left;
}
.container {
  padding: 60px 0 0px 0px;
  min-height: 550px;
  font-family: var(--secondary-font);
  width: 90%;
  margin: 0 auto;
}

.clickable {
  cursor: pointer;
}

.container-workers {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 10px;
  font-family: var(--principal-font);
  margin: 0 auto;
  align-items: baseline;
}
.container-btn {
  padding-top: 30px;
}
.subtitle {
  font-weight: 300;
  text-align: justify;
  font-size: 21px;
}

.site-name {
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 70px;
}

.blog-section {
  width: 90%;
  margin: 0 auto;
  margin-top: -190px;
}
.podcast-section {
  width: 90%;
  margin: 0 auto;
}
.blog {
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 70px;
  font-family: var(--secondary-font);
  text-align: left;
}

@media (max-width: 600px) {
  .blog-section {
    margin-top: -60px;
    padding: 0;
  }
  .site-name {
    font-size: 52px;
    text-align: center;
  }
  .subtitle {
    font-size: 1.17rem;
  }
  .blog {
    font-size: 52px;
  }
  .container {
    padding: 10px;
    background-size: 100%;
    margin: 30px 10px 0px 10px;
  }
  .container-btn {
    margin-right: 0px;
  }
  .whatwedo {
    padding-top: 50px;
  }
  .container-workers {
    grid-template-columns: repeat(1, 1fr);
  }
  .call-to-action {
    margin-bottom: 60px;
  }
}

.pax-pic {
  width: 220px;
}

h1 {
  font-family: var(--secondary-font);
  margin-bottom: 0;
  font-size: 20px;
}
.role {
  font-family: var(--secondary-font);
  margin-bottom: 20px;
}

.container-loader {
  padding: 50px;
}

@media (min-width: 768px) and (max-width: 830px) {
  .container-workers {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 10px;
  }
}
</style>
