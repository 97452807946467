<template>
  <BannerStrategic />
  <StrategicServices />
</template>
<script>
import BannerStrategic from "../components/StrategicComponent/BannerStrategic.vue";
import StrategicServices from "../components/StrategicComponent/StrategicServices.vue";

export default {
  name: "Strategic",
  components: {
    BannerStrategic,
    StrategicServices,
  },
};
</script>
