<template>
  <div class="container">
    <h2 class="breadcrumb">{{ $t("home.breadcrumb-third") }}</h2>
    <div class="logos-fila-1">
      <img src="../../assets/Logos-clientes/Unilever.png" alt="" />
      <img src="../../assets/Logos-clientes/sierra-eco-foods.png" alt="" />
      <img src="../../assets/Logos-clientes/purina.png" alt="" />
      <img
        src="../../assets/Logos-clientes/connected-mobility-HUB.png"
        alt=""
      />
      <img src="../../assets/Logos-clientes/ashoka.png" alt="" />
      <img src="../../assets/Logos-clientes/logo-impact.png" alt="" />
    </div>
    <div class="logos-fila-2">
      <img src="../../assets/Logos-clientes/alapacana.png" alt="" />
      <img
        src="../../assets/Logos-clientes/logotipo_digital_06_bn.jpg"
        alt=""
      />
      <img
        src="../../assets/Logos-clientes/LOGO_FuckUpNights_CURVAS-01(2).png"
        alt=""
      />
      <img src="../../assets/Logos-clientes/Promofarma.png" alt="" />
      <img src="../../assets/Logos-clientes/holis.png" alt="" />
      <img src="../../assets/Logos-clientes/BOD.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {},
};
</script>
<style scoped>
.breadcrumb {
  font-size: 17px;
  font-family: var(--secondary-font);
  text-align: left;
  margin-top: 90px;
  margin-bottom: 90px;
  padding-left: 50px;
  font-weight: 600;
}
img {
  width: 10%;
}
.logos-fila-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 50px;
}
.logos-fila-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 50px 90px 50px;
}
@media (max-width: 600px) {
  img {
    width: 70%;
  }
  .breadcrumb {
    padding-left: 30px;
  }
  .logos-fila-1,
  .logos-fila-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    justify-items: center;
  }
}
</style>
